import React from 'react'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
 <>
    <div id="main-content" className="main-wrap" role="main">
    <article className="main-content">
     <div className="row section--image-bg expanded collapse gen-padding hero hero--generic hero--home vc_custom_1647882924472">
      <div className="columns small-12 medium-12 large-12">
        <div className="row row--justify-center">
          <div className="columns small-12 medium-24 large-8">
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <div className="image--featured-banner">
                  <img
                    decoding="async"
                    className="alignnone size-medium wp-image-83877"
                    src="app/uploads/trust-signals1.svg"
                    alt="asas"
                    width={543}
                    height={37}
                  />
                </div>
              </div>
            </div>
            <h1
              style={{ textAlign: "center" }}
              className="vc_custom_heading text--is-uppercase"
            >
              Find Internet &amp; Cable TV Providers In Your Area
            </h1>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p>Best Internet and Tv providers in your area.</p>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element ">
             
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="row medium-padding">
      <div className="columns small-12 medium-12 large-12">
        <div className="wpb_text_column wpb_content_element ">
          <div className="wpb_wrapper"></div>
        </div>
        <div className="wpb_single_image wpb_content_element vc_align_center">
          <figure className="wpb_wrapper vc_figure">
            
              <img
                width={2501}
                height={418}
                src="app/uploads/2023/11/ctv-holiday-dream-job-2023-in-article-banner-cta.png"
                className="vc_single_image-img attachment-full"
                alt="Earn $2,500 by watching 25 holiday movies!"
                decoding="async"
                title="ctv-holiday-dream-job-2023-in-article-banner-cta"
                srcSet="https://www.cabletv.com/app/uploads/2023/11/ctv-holiday-dream-job-2023-in-article-banner-cta.png 2501w, https://www.cabletv.com/app/uploads/2023/11/ctv-holiday-dream-job-2023-in-article-banner-cta-1115x186.png 1115w, https://www.cabletv.com/app/uploads/2023/11/ctv-holiday-dream-job-2023-in-article-banner-cta-1565x262.png 1565w, https://www.cabletv.com/app/uploads/2023/11/ctv-holiday-dream-job-2023-in-article-banner-cta-1536x257.png 1536w, https://www.cabletv.com/app/uploads/2023/11/ctv-holiday-dream-job-2023-in-article-banner-cta-2048x342.png 2048w"
                sizes="(max-width: 2501px) 100vw, 2501px"
              />
          </figure>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="columns small-12 medium-12 large-12">
        <div className="wpb_text_column wpb_content_element ">
          <div className="wpb_wrapper"></div>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="columns small-12 medium-12 large-12">
        <div className="row row--justify-center margin--top-30 margin--bottom-30">
          <div className="columns small-12 medium-24 large-8">
            <h2
              style={{ textAlign: "center" }}
              className="vc_custom_heading text--is-uppercase"
            >
              Let us provide you with the greatest internet and TV service.
            </h2>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p style={{ textAlign: "center" }}>
                Here at CableTV.com, we make it simple to find TV and internet in your region so you can go straight to the enjoyable part: owning TV and internet.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="columns small-12 medium-24 large-3">
            <div className="wpb_text_column wpb_content_element  text--is-uppercase _static-content--center">
              <div className="wpb_wrapper">
                <p>
                  <img
                    decoding="async"
                    className="vc_single_image-img attachment-medium"
                    src="app/uploads/tv-icon.svg"
                    alt=""
                  />
                </p>
                <h4>Tv Service</h4>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element  _static-content--center">
              <div className="wpb_wrapper">
                <p className="p1" style={{ textAlign: "left" }}>
                  <span className="s1">
                  Your preferred television programs and films are available for viewing on cable TV, satellite TV, and internet streaming options. To help you choose your favorite method of tuning in, we'll break down each of these TV alternatives.
                  </span>
                </p>
                
                  See TV Providers{" "}
              </div>
            </div>
            <div className="vc_empty_space" style={{ height: 30 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
          <div className="columns small-12 medium-24 large-3">
            <div className="wpb_text_column wpb_content_element  text--is-uppercase _static-content--center">
              <div className="wpb_wrapper">
                <p>
                  <img
                    decoding="async"
                    className="vc_single_image-img attachment-medium"
                    src="app/uploads/internet-icon.svg"
                    alt=""
                  />
                </p>
                <h4>Internet Service</h4>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element  _static-content--center">
              <div className="wpb_wrapper">
                <p className="p1">
                  <span className="s1">
                  You should have affordable internet that offers a ton of data at fast download speeds. We examine and investigate all of the main US internet providers so that your home network will function flawlessly.
                  </span>
                </p>
                
                  See Internet Providers{" "}
              </div>
            </div>
            <div className="vc_empty_space" style={{ height: 30 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
          <div className="columns small-12 medium-24 large-3">
            <div className="wpb_text_column wpb_content_element  text--is-uppercase _static-content--center">
              <div className="wpb_wrapper">
                <p>
                  <img
                    decoding="async"
                    className="vc_single_image-img attachment-medium"
                    src="app/uploads/compare-icon.svg"
                    alt=""
                  />
                </p>
                <h4>Compare Providers</h4>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element  _static-content--center">
              <div className="wpb_wrapper">
                <p className="p1">
                  <span className="s1">
                  You should be able to get internet at a great price that offers gobs of data and high download speeds. We examine and investigate all of the main US internet providers so that your home network will function flawlessly.
                  </span>
                </p>
                
                  Compare Providers{" "}
              </div>
            </div>
            <div className="vc_empty_space" style={{ height: 30 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
          <div className="columns small-12 medium-24 large-3">
            <div className="wpb_text_column wpb_content_element  text--is-uppercase _static-content--center">
              <div className="wpb_wrapper">
                <p>
                  <img
                    decoding="async"
                    className="vc_single_image-img attachment-medium"
                    src="app/uploads/HelpfulResources.svg"
                    alt=""
                  />
                </p>
                <h4>Helpful Resources</h4>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element  _static-content--center">
              <div className="wpb_wrapper">
                <p className="p1">
                  <span className="s1">
                  You should have access to affordable internet that offers large data volumes and quick download speeds. To ensure that your home Wi-Fi is fantastic, we investigate and evaluate each of the main US internet providers.
                  </span>
                </p>
                
                  Browse Articles{" "}
              </div>
            </div>
            <div className="vc_empty_space" style={{ height: 30 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="row section--light-bg expanded collapse gen-padding">
      <div className="columns small-12 medium-12 large-12">
        <div className="row row--justify-center">
          <div className="columns small-12 medium-24 large-8">
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2
                  className="text--is-uppercase"
                  style={{ textAlign: "center" }}
                >
                  Receive prompt responses free of worry
                </h2>
                <p style={{ textAlign: "center" }}>
                If you have the appropriate tool for the job, answering certain queries is simple. Neighbor, save yourself the elbow grease—our toolbox is prepared for you.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="columns small-12 medium-24 large-6">
            <div className="article-callout">
              <div className="article-callout__image">
                  <img
                    width={1115}
                    height={586}
                    src="app/uploads/CTV-What-to-Watch-1115x586.png"
                    alt="CTV-What-to-Watch"
                    loading="lazy"
                  />
              </div>
              <div
                className="article-callout__text"
                data-equal="ac-text"
                data-equal-breakpoint={1024}
              >
                <h3 className="text--is-uppercase">
                What program ought to you watch next?
                </h3>
                <p className="p1">
                With the aid of our TV show algorithm, you may discover the ideal show for your preferences without having to flip through the channels. It is comparable to a mystical TV mind reader.
                </p>
              </div>
              <Link
                to="/contact"
                className="button button--is-big button--generic button--is-short-unlimited text--is-uppercase"
                target=""
              >
                Find your next show{" "}
              </Link>
            </div>
          </div>
          <div className="columns small-12 medium-24 large-6">
            <div className="article-callout">
              <div className="article-callout__image">
                  <img
                    src="app/uploads/Cabletv.com-tv-viewing-distance-calculator-627x376-1.png"
                    alt="Cabletv.com-tv-viewing-distance-calculator-627×376"
                    loading="lazy"
                  />
              </div>
              <div
                className="article-callout__text"
                data-equal="ac-text"
                data-equal-breakpoint={1024}
              >
                <h3 className="text--is-uppercase">
                How far away from the TV should your couch be?
                </h3>
                <p className="p1">
                It may seem little, but the placement of your TV has a significant impact on both your comfort and its visibility. Thanks to our TV distance calculator, you no longer have to suffer from blindness as your mother had advised.
                </p>
              </div>
              <Link
                to="/contact"
                className="button button--is-big button--generic button--is-short-unlimited text--is-uppercase"
                target=""
              >
                Take a seat{" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row section--light-purple expanded collapse large-padding">
      <div className="columns small-12 medium-12 large-12">
        <div className="row ">
          <div className="columns small-12 medium-24 large-12">
            <div className="row align-center vc-email-subscribe__wrapper">
              <div className="columns small-12 large-6">
                <div className="callout-area">
                  <div className="callout-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={65}
                      height={54}
                    >
                      <path
                        fill="#1D1D49"
                        fillRule="evenodd"
                        d="M64.5 7c0-3.667-2.88-6.667-6.4-6.667H6.9C3.38.333.5 3.333.5 7v40c0 3.667 2.88 6.667 6.4 6.667h51.2c3.52 0 6.4-3 6.4-6.667V7zm-6.4 0L32.5 23.667 6.9 7h51.2zm0 40H6.9V13.667l25.6 16.666 25.6-16.666V47z"
                      />
                    </svg>{" "}
                  </div>
                  <div className="callout-text">
                    <h3>Connect it to the most popular TV and online content.</h3>{" "}
                    <p>
                    Register for our email to be informed about upcoming offers, premieres of new shows, and other events.
                    </p>{" "}
                  </div>
                </div>
              </div>
              <div className="columns small-12 large-6">
                <div className="vc-email-subscribe">
                  <div className="vc-email-subscribe__inline-form">
                    <form
                      method="POST"
                      action="https://cabletv.activehosted.com/proc.php"
                      id="_form_1_"
                      className="_form _form_1 _inline-form"
                    >
                      <input type="hidden" name="u" defaultValue={1} />
                      <input type="hidden" name="f" defaultValue={1} />
                      <input type="hidden" name="s" />
                      <input type="hidden" name="c" defaultValue={0} />
                      <input type="hidden" name="m" defaultValue={0} />
                      <input type="hidden" name="act" defaultValue="sub" />
                      <input type="hidden" name="v" defaultValue={2} />
                      <div className="_form-content">
                        <div className="_field-wrapper">
                          <input
                            className="field-email"
                            id="email"
                            type="text"
                            name="email"
                            placeholder="Email Address"
                            required=""
                          />
                          <label className="show-for-sr" htmlFor="email">
                            Email*
                          </label>
                        </div>
                        <div className="_field-wrapper">
                          <input
                            className="field-zip"
                            id="field[1]"
                            type="text"
                            name="field[1]"
                            placeholder="Zip Code"
                            minLength={5}
                            maxLength={5}
                            pattern="[0-9]{5}"
                            required=""
                          />
                          <label className="show-for-sr" htmlFor="field[1]">
                            Zip code*
                          </label>
                        </div>
                        <div className="_button-wrapper _inline-style">
                          <button
                            id="_form_1_submit"
                            className="button button--generic"
                            type="submit"
                          >
                            Sign Up
                          </button>
                        </div>
                        <div className="_clear-element"></div>
                      </div>
                      <div
                        className="_form-thank-you"
                        style={{ display: "none", textAlign: "center" }}
                      ></div>
                    </form>
                    <p className="vc-email-subscribe__disclaimer">
                      By signing up, you agree to our{" "}
                      <Link to="/contact">
                        Terms of Use
                      </Link>{" "}
                      and{" "}
                      <Link to="/contact">
                        Privacy Policy
                      </Link>
                      
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="row section--gradient2 expanded collapse gen-padding">
      <div className="columns small-12 medium-12 large-12">
        <div className="row ">
          <div className="columns small-12 medium-24 large-12">
            <h2
              style={{ textAlign: "left" }}
              className="vc_custom_heading text--is-uppercase"
            >
              Get Informed About the Greatest of the Best with Our Editor's Choice Awards
            </h2>
            <div className="vc_empty_space" style={{ height: 30 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
          <div className="columns small-12 medium-24 large-4">
            <div className="post-block">
              <div className="post-block--featured-image">
               
                  <img
                    data-src="https://www.cabletv.com/app/uploads/CTV_Best-TV-Service-Providers-Featured.png"
                    className="attachment-post-thumbnail size-post-thumbnail lazyload wp-post-image"
                    alt=""
                    decoding="async"
                    srcSet="https://www.cabletv.com/app/uploads/CTV_Best-TV-Service-Providers-Featured.png 1201w, https://www.cabletv.com/app/uploads/CTV_Best-TV-Service-Providers-Featured-1115x585.png 1115w"
                    sizes="(max-width: 1201px) 100vw, 1201px"
                  />
              </div>
              <div className="post-block--title dark-bg">
                <h4>
                
                    Best TV Service Providers for <span>2023</span>: Plans,
                    Prices, and More
                </h4>
              </div>
              <div className="post-block--author-area dark-bg">
                <div className="author-area--image-link">
                    <img
                      alt=""
                      data-src="https://secure.gravatar.com/avatar/4279a67f8f571e5ec9a837f727fa7cc6?s=28&d=mm&r=g"
                      srcSet="https://secure.gravatar.com/avatar/4279a67f8f571e5ec9a837f727fa7cc6?s=56&d=mm&r=g 2x"
                      className="avatar avatar-28 photo lazyload"
                      height={28}
                      width={28}
                      decoding="async"
                    />{" "}
                </div>
                <div className="author-area--name dark-bg">
                  By{" "}
                 
                    Randy Harward
                </div>
                <div className="author-area--date dark-bg">Nov 10, 2023</div>
              </div>
              <p className="post-block--excerpt dark-bg">
              You should spend your best life on the sofa, according to CableTV.com. In the event that you choose to go that route, you must choose which TV provider best suits your demands and lifestyle. Which offers the best value or is the least expensive? Which is the finest all around then?{" "}
              </p>
            </div>
          </div>
          <div className="columns small-12 medium-24 large-4">
            <div className="post-block">
              <div className="post-block--featured-image">
                  <img
                    data-src="https://www.cabletv.com/app/uploads/CTV_Best-Streaming_Services.png"
                    className="attachment-post-thumbnail size-post-thumbnail lazyload wp-post-image"
                    alt="Get the best streaming service for you"
                    decoding="async"
                    srcSet="https://www.cabletv.com/app/uploads/CTV_Best-Streaming_Services.png 1200w, https://www.cabletv.com/app/uploads/CTV_Best-Streaming_Services-1115x585.png 1115w"
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
              </div>
              <div className="post-block--title dark-bg">
                <h4>
                    Best Streaming Services <span>2023</span>: Amazon Prime
                    Video, Hulu, and More
                </h4>
              </div>
              <div className="post-block--author-area dark-bg">
                <div className="author-area--image-link">
                    <img
                      alt=""
                      data-src="https://secure.gravatar.com/avatar/30093259076f5169890fb1bd2ce2e5cc?s=28&d=mm&r=g"
                      srcSet="https://secure.gravatar.com/avatar/30093259076f5169890fb1bd2ce2e5cc?s=56&d=mm&r=g 2x"
                      className="avatar avatar-28 photo lazyload"
                      height={28}
                      width={28}
                      decoding="async"
                    />{" "}
                </div>
                <div className="author-area--name dark-bg">
                  By{" "}
                  
                    Olivia Bono
                </div>
                <div className="author-area--date dark-bg">Oct 12, 2023</div>
              </div>
              <p className="post-block--excerpt dark-bg">
              We also adore Disney+ for families and HBO Max for original material if you already have Netflix or want to add another high-quality video streaming service. We don't stop there, though. Discover which on-demand streaming service is ideal for you by reading on.{" "}
              </p>
            </div>
          </div>
          <div className="columns small-12 medium-24 large-4">
            <div className="post-block">
              <div className="post-block--featured-image">
                  <img
                    data-src="https://www.cabletv.com/app/uploads/CTV_Best-Internet-Providers-Featured.png"
                    className="attachment-post-thumbnail size-post-thumbnail lazyload wp-post-image"
                    alt=""
                    decoding="async"
                    srcSet="https://www.cabletv.com/app/uploads/CTV_Best-Internet-Providers-Featured.png 1201w, https://www.cabletv.com/app/uploads/CTV_Best-Internet-Providers-Featured-1115x585.png 1115w"
                    sizes="(max-width: 1201px) 100vw, 1201px"
                  />
              </div>
              <div className="post-block--title dark-bg">
                <h4>
                    Best Internet Providers 2023: Plans, Prices, Deals, and More
                </h4>
              </div>
              <div className="post-block--author-area dark-bg">
                <div className="author-area--image-link">
                    <img
                      alt=""
                      data-src="https://secure.gravatar.com/avatar/72902a8b38bba670d5eaf6b29f7d491b?s=28&d=mm&r=g"
                      srcSet="https://secure.gravatar.com/avatar/72902a8b38bba670d5eaf6b29f7d491b?s=56&d=mm&r=g 2x"
                      className="avatar avatar-28 photo lazyload"
                      height={28}
                      width={28}
                      decoding="async"
                    />{" "}
                </div>
                <div className="author-area--name dark-bg">
                  By{" "}
                  
                    Rachel Oaks
                </div>
                <div className="author-area--date dark-bg">Nov 10, 2023</div>
              </div>
              <p className="post-block--excerpt dark-bg">
              We have five suggestions for the top internet providers, so you're covered whether you're searching for the quickest home internet or the greatest internet service available.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="row row--justify-right">
          <div className="columns small-12 medium-24 large-12">
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row gen-padding">
      <div className="columns small-12 medium-12 large-12">
        <div className="row row--justify-center margin--bottom-30">
          <div className="columns small-12 medium-24 large-8">
            <h2
              style={{ textAlign: "center" }}
              className="vc_custom_heading text--is-uppercase"
            >
              Let's start watching!
            </h2>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p style={{ textAlign: "center" }}>
                Superfans, celebrate! Whether it's professional football, live news, or streaming original content, our TV recommendations make it easy for you to enjoy the entertainment you love.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row ">
          <div className="columns small-12 medium-24 large-4">
            <div className="post-block">
              <div className="post-block--featured-image">
                  <img
                    data-src="https://www.cabletv.com/app/uploads/CTV_NFL-Games.png"
                    className="attachment-post-thumbnail size-post-thumbnail lazyload wp-post-image"
                    alt="How to watch NFL Games"
                    decoding="async"
                    srcSet="https://www.cabletv.com/app/uploads/CTV_NFL-Games.png 2500w, https://www.cabletv.com/app/uploads/CTV_NFL-Games-1115x558.png 1115w, https://www.cabletv.com/app/uploads/CTV_NFL-Games-1565x783.png 1565w, https://www.cabletv.com/app/uploads/CTV_NFL-Games-1536x769.png 1536w, https://www.cabletv.com/app/uploads/CTV_NFL-Games-2048x1025.png 2048w"
                    sizes="(max-width: 2500px) 100vw, 2500px"
                  />
              </div>
              <div className="post-block--title">
                <h4>
                    How To Watch NFL Games 2023
                </h4>
              </div>
              <div className="post-block--author-area">
                <div className="author-area--image-link">
                    <img
                      alt=""
                      data-src="https://secure.gravatar.com/avatar/e2b9a954ace84a0da4797f45864a31b4?s=28&d=mm&r=g"
                      srcSet="https://secure.gravatar.com/avatar/e2b9a954ace84a0da4797f45864a31b4?s=56&d=mm&r=g 2x"
                      className="avatar avatar-28 photo lazyload"
                      height={28}
                      width={28}
                      decoding="async"
                    />{" "}
                </div>
                <div className="author-area--name">
                  By{" "}
                 
                    Taylor Kujawa
                </div>
                <div className="author-area--date">Nov 3, 2023</div>
              </div>
              <p className="post-block--excerpt">
              Are you prepared for a football game? Discover the top methods for watching and streaming NFL games in 2023–2024, along with important dates and channel listings.{" "}
              </p>
            </div>
            <div className="vc_empty_space" style={{ height: 30 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="post-block">
              <div className="post-block--featured-image">
                  <img
                    data-src="https://www.cabletv.com/app/uploads/2023/09/CTV_NBAInSeasonTournament.jpg"
                    className="attachment-post-thumbnail size-post-thumbnail lazyload wp-post-image"
                    alt="How to Watch the NBA In-Season Tournament"
                    decoding="async"
                    srcSet="https://www.cabletv.com/app/uploads/2023/09/CTV_NBAInSeasonTournament.jpg 1200w, https://www.cabletv.com/app/uploads/2023/09/CTV_NBAInSeasonTournament-1115x585.jpg 1115w"
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
              </div>
              <div className="post-block--title">
                <h4>
                    How To Watch the NBA In-Season Tournament 2023
                </h4>
              </div>
              <div className="post-block--author-area">
                <div className="author-area--image-link">
                    <img
                      alt=""
                      data-src="https://secure.gravatar.com/avatar/e2b9a954ace84a0da4797f45864a31b4?s=28&d=mm&r=g"
                      srcSet="https://secure.gravatar.com/avatar/e2b9a954ace84a0da4797f45864a31b4?s=56&d=mm&r=g 2x"
                      className="avatar avatar-28 photo lazyload"
                      height={28}
                      width={28}
                      decoding="async"
                    />{" "}
                </div>
                <div className="author-area--name">
                  By{" "}
                  
                    Taylor Kujawa
                </div>
                <div className="author-area--date">Nov 6, 2023</div>
              </div>
              <p className="post-block--excerpt">
              Fans may expect a new gaming edge as the NBA gets ready for its 78th season. The NBA In-Season Tournament, which begins in November, is expected to provide another level of interest to the first half of the regular season.{" "}
              </p>
            </div>
          </div>
          <div className="columns small-12 medium-24 large-4">
            <div className="post-block">
              <div className="post-block--featured-image">
                  <img
                    data-src="https://www.cabletv.com/app/uploads/CTV_Watch-Awards-Show-Featured.png"
                    className="attachment-post-thumbnail size-post-thumbnail lazyload wp-post-image"
                    alt=""
                    decoding="async"
                    srcSet="https://www.cabletv.com/app/uploads/CTV_Watch-Awards-Show-Featured.png 1200w, https://www.cabletv.com/app/uploads/CTV_Watch-Awards-Show-Featured-1115x586.png 1115w"
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
              </div>
              <div className="post-block--title">
                <h4>
                    How To Watch Upcoming Awards Shows 2023
                </h4>
              </div>
              <div className="post-block--author-area">
                <div className="author-area--image-link">
                    <img
                      alt=""
                      data-src="https://secure.gravatar.com/avatar/30093259076f5169890fb1bd2ce2e5cc?s=28&d=mm&r=g"
                      srcSet="https://secure.gravatar.com/avatar/30093259076f5169890fb1bd2ce2e5cc?s=56&d=mm&r=g 2x"
                      className="avatar avatar-28 photo lazyload"
                      height={28}
                      width={28}
                      decoding="async"
                    />{" "}
                </div>
                <div className="author-area--name">
                  By{" "}
                  
                    Olivia Bono
                </div>
                <div className="author-area--date">Nov 10, 2023</div>
              </div>
              <p className="post-block--excerpt">
              See your preferred television program, movie, or artist with our schedule of forthcoming award shows. Please don't worry if anything is marked as TBD; we will provide an update as soon as we learn more about the event.{" "}
              </p>
            </div>
            <div className="vc_empty_space" style={{ height: 30 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="post-block">
              <div className="post-block--featured-image">
                  <img
                    data-src="https://www.cabletv.com/app/uploads/2023/03/CTV-Sports-2023.jpg"
                    className="attachment-post-thumbnail size-post-thumbnail lazyload wp-post-image"
                    alt="How to watch upcoming sports events"
                    decoding="async"
                    srcSet="https://www.cabletv.com/app/uploads/2023/03/CTV-Sports-2023.jpg 1200w, https://www.cabletv.com/app/uploads/2023/03/CTV-Sports-2023-1115x585.jpg 1115w"
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
              </div>
              <div className="post-block--title">
                <h4>
                    How To Watch Upcoming Sports Events 2023
                </h4>
              </div>
              <div className="post-block--author-area">
                <div className="author-area--image-link">
                    <img
                      alt=""
                      data-src="https://secure.gravatar.com/avatar/e2b9a954ace84a0da4797f45864a31b4?s=28&d=mm&r=g"
                      srcSet="https://secure.gravatar.com/avatar/e2b9a954ace84a0da4797f45864a31b4?s=56&d=mm&r=g 2x"
                      className="avatar avatar-28 photo lazyload"
                      height={28}
                      width={28}
                      decoding="async"
                    />{" "}
                </div>
                <div className="author-area--name">
                  By{" "}
                
                    Taylor Kujawa
                </div>
                <div className="author-area--date">Nov 3, 2023</div>
              </div>
              <p className="post-block--excerpt">
              With our often updated TV sports calendar, you can experience the thrills and chills of every big sporting event in 2023.{" "}
              </p>
            </div>
          </div>
          <div className="columns small-12 medium-24 large-4">
            <div className="post-block">
              <div className="post-block--featured-image">
                  <img
                    data-src="https://www.cabletv.com/app/uploads/CTV_Live_TV-News_For_Free-Featured.png"
                    className="attachment-post-thumbnail size-post-thumbnail lazyload wp-post-image"
                    alt=""
                    decoding="async"
                    srcSet="https://www.cabletv.com/app/uploads/CTV_Live_TV-News_For_Free-Featured.png 1201w, https://www.cabletv.com/app/uploads/CTV_Live_TV-News_For_Free-Featured-1115x585.png 1115w"
                    sizes="(max-width: 1201px) 100vw, 1201px"
                  />
              </div>
              <div className="post-block--title">
                <h4>
                    How to Watch Live TV News for Free: ABC, CBS, FOX News, NBC,
                    and More
                </h4>
              </div>
              <div className="post-block--author-area">
                <div className="author-area--image-link">
                    <img
                      alt=""
                      data-src="https://secure.gravatar.com/avatar/d0a423da4615a10fe8d77c9af9bb1510?s=28&d=mm&r=g"
                      srcSet="https://secure.gravatar.com/avatar/d0a423da4615a10fe8d77c9af9bb1510?s=56&d=mm&r=g 2x"
                      className="avatar avatar-28 photo lazyload"
                      height={28}
                      width={28}
                      decoding="async"
                    />{" "}
                </div>
                <div className="author-area--name">
                  By{" "}
                  
                    Mike Strayer
                </div>
                <div className="author-area--date">Aug 7, 2023</div>
              </div>
              <p className="post-block--excerpt">
              News travels even quicker than life itself. Fortunately, there are several live TV news networks that you can watch and stream for free during these exciting moments.{" "}
              </p>
            </div>
            <div className="vc_empty_space" style={{ height: 30 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="post-block">
              <div className="post-block--featured-image">
                  <img
                    data-src="https://www.cabletv.com/app/uploads/2023/09/TV-Premieres-November-2023.jpg"
                    className="attachment-post-thumbnail size-post-thumbnail lazyload wp-post-image"
                    alt=""
                    decoding="async"
                    srcSet="https://www.cabletv.com/app/uploads/2023/09/TV-Premieres-November-2023.jpg 1200w, https://www.cabletv.com/app/uploads/2023/09/TV-Premieres-November-2023-1115x585.jpg 1115w"
                    sizes="(max-width: 1200px) 100vw, 1200px"
                  />
              </div>
              <div className="post-block--title">
                <h4>
                    TV Premieres November 2023
                </h4>
              </div>
              <div className="post-block--author-area">
                <div className="author-area--image-link">
                    <img
                      alt=""
                      data-src="https://secure.gravatar.com/avatar/08691d1a9317d6e79ce6618894315d5a?s=28&d=mm&r=g"
                      srcSet="https://secure.gravatar.com/avatar/08691d1a9317d6e79ce6618894315d5a?s=56&d=mm&r=g 2x"
                      className="avatar avatar-28 photo lazyload"
                      height={28}
                      width={28}
                      decoding="async"
                    />{" "}
                </div>
                <div className="author-area--name">
                  By{" "}
                 
                    Bill Frost
                </div>
                <div className="author-area--date">Oct 26, 2023</div>
              </div>
              <p className="post-block--excerpt">
              With more than 100 new and returning TV series and films, such as Only Murders In the Building, Billions, and Physical, we're enjoying the dog days of summer.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="row row--justify-right">
          <div className="columns small-12 medium-24 large-12">
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            
          </div>
        </div>
      </div>
    </div>{" "}
  </article>
</div>

 </>

  )
}

export default Home