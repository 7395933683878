import React from "react";
import { Link } from "react-router-dom";
import Tv_APP from '../uploads/AppleTV-4k-4-Mini-1.jpg' 
const Bundles = () => {
  return (
    <>
      <div id="main-content" className="main-wrap page" role="main">
        <article
          className="main-content post-103940 page type-page status-publish has-post-thumbnail hentry"
          id="post-103940"
        >
          <div className="grid-x expanded collapse">
            <div className="cell small-12">
              <section
                className="hero hero--generic hero--has-components"
                style={{ background: "#1d1d49" }}
              >
                <div className="row align-center">
                  <div className="columns small-12 large-10">
                    <h1 className="entry-title">
                      Bundle  Internet, TV, and Voice
                    </h1>
                  </div>
                </div>
                <div className="row align-center">
                  <div className="columns small-12 large-12">
                    <div className="hero-components ">
                      <div className="row full-width-hero expanded collapse large-padding">
                        <div className="columns small-12 medium-12 large-6">
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <ul>
                                <li
                                  style={{ marginBottom: 0, textAlign: "left" }}
                                >
                                  Combine TV, phone, and internet service on one
                                  easy bill
                                </li>
                                <li
                                  style={{ marginBottom: 0, textAlign: "left" }}
                                >
                                  Get fast maximum internet speeds between 300
                                  Mbps and 1,000 Mbps
                                </li>
                                <li
                                  style={{ marginBottom: 0, textAlign: "left" }}
                                >
                                  Add  Mobile for additional savings
                                </li>
                                <li
                                  style={{ marginBottom: 0, textAlign: "left" }}
                                >
                                  No contracts
                                </li>
                              </ul>
                              <p
                                className="h4"
                                style={{ paddingTop: 25, textAlign: "left" }}
                              >
                                TV and internet packages starting at
                              </p>
                              <p style={{ textAlign: "left" }}>
                                <span
                                  className="h2"
                                  style={{ color: "#ffffff" }}
                                >
                                  $109.98
                                  <span className="h4">
                                    /mo. for 12 mo to=""s.
                                  </span>
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="columns small-12 medium-24 large-3">
                              <div className="vc_btn3-container  text-left margin-bottom">
                                <Link
                                  to="/contact"
                                  className="button button--is-big  button--generic width--standard"
                                  rel="nofollow noopener"
                                >
                                  View Plans
                                  <span className="show-for-sr">
                                    {" "}
                                    for 
                                  </span>
                                </Link>
                              </div>
                            </div>
                            <div className="columns small-12 medium-24 large-9" />
                          </div>
                          <div
                            className="vc_empty_space"
                            style={{ height: 32 }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                        </div>
                        <div className="columns small-12 medium-12 large-6">
                          <div className="wpb_text_column wpb_content_element  vc_custom_1670604721842">
                            <div className="wpb_wrapper">
                              <p>
                                <img
                                  className="avatar alignnone wp-image-62562"
                                  src="../app/uploads/CTV_-directresponse-bundles.jpg"
                                  alt="Woman on a laptop watching TV with friends"
                                  width={600}
                                  height={400}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="row expanded collapse">
            <div className="column small-12">
              <section
                className="section--cta-sticky"
                id="cta-sticky-container"
              >
                <div className="row expanded collapsed align-center-middle">
                  <div className="columns small-12 medium-10 cta-sticky--column-padding-restrict">
                    <div className="cta-sticky" data-component="Call To Action">
                      <div className="row expanded collapsed align-center-middle">
                        <div className="columns small-12 show-for-small-only cta-sticky__button-area text-center no-padding-left-right">
                          <Link
                            className="button button--generic button--is-big button--is-bold button--fills-container"
                            to="/contact"
                            rel="nofollow"
                          >
                            Order Online
                          </Link>
                        </div>
                        <div className="columns small-8 show-for-medium no-padding-left">
                          <div className="cta-sticky__heading">
                            <h2 className="text--is-light">
                              Get  now{" "}
                            </h2>
                          </div>
                        </div>
                        <div className="columns small-4 show-for-medium no-padding-right">
                          <Link
                            className="button button--generic button--is-short-unlimited button--is-big button--is-bold float-right"
                            rel="nofollow"
                            to="/contact"
                          >
                            Order Online
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>
      <div className="row align-center large-padding">
        <div className="columns small-12 large-10 bg-white large-padding-full">
          <div className="post-wrap">
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2>Best  TV, Internet, and Voice bundles</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-4">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2 style={{ fontSize: "1.5rem" }}>Internet + TV Select</h2>
                  </div>
                </div>
                <p
                  style={{ fontSize: 36, textAlign: "left" }}
                  className="vc_custom_heading"
                >
                  <b>$109.98/mo.</b>
                </p>
                <div className="vc_empty_space" style={{ height: 15 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Up to 300 Mbps download speeds</li>
                      <li>125+ channels</li>
                      <li>
                        Channels include ABC, Comedy Central, and Food Network
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    View Plan
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
              <div className="columns small-12 medium-12 large-4">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2 style={{ fontSize: "1.5rem" }}>
                      Internet Ultra + TV Select
                    </h2>
                  </div>
                </div>
                <p
                  style={{ fontSize: 36, textAlign: "left" }}
                  className="vc_custom_heading"
                >
                  <b>$129.98/mo.</b>
                </p>
                <div className="vc_empty_space" style={{ height: 15 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Up to 500 Mbps download speeds</li>
                      <li>125+ channels</li>
                      <li>Internet speeds fast enough for large families</li>
                    </ul>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    View Plan
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
              <div className="columns small-12 medium-12 large-4">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2 style={{ fontSize: "1.5rem" }}>
                      Internet Gig + TV Select + Voice
                    </h2>
                  </div>
                </div>
                <p
                  style={{ fontSize: 36, textAlign: "left" }}
                  className="vc_custom_heading"
                >
                  <b>$169.97/mo.</b>
                </p>
                <div className="vc_empty_space" style={{ height: 15 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Up to 1,000 Mbps download speeds</li>
                      <li>125+ channels</li>
                      <li>
                        Get landline phone service and fastest internet speeds
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    View Plan
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row vc_custom_1655852548078">
              <div className="columns small-12 medium-12 large-12">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      Enter your zip code below to learn about  bundle
                      options in your town.
                    </p>
                    <form
                      className="zip-finder zip-finder--inline"
                      data-form-location="Body"
                      data-abide=""
                      noValidate=""
                      data-zip-finder=""
                    >
                      <div className="zip-finder__wrapper">
                        <div className="zip-finder__fields">
                          <div className="zip-finder__input">
                            <input
                              type="tel"
                              name="zipcode"
                              required="required"
                              placeholder="Zip code"
                              autoComplete="off"
                              maxLength={5}
                              pattern="[0-9]{5}"
                            />
                            <span className="form-error">
                              Please enter a valid zip code.
                            </span>
                          </div>
                          <div className="zip-finder__button">
                            <button
                              className="button button--has-icon-on-right button--hide-spinner"
                              type="submit"
                            >
                              <h4 className="zip-finder__button__header">
                                Find
                              </h4>
                              <div className="button__icon">
                                <svg>
                                  <use xlinkto="#pin" />
                                </svg>
                              </div>
                              <div
                                className="spinner"
                                role="alert"
                                aria-live="assertive"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <section className="vc_cta3-container">
                  <div className="vc_general vc_cta3 vc_cta3-style-outline vc_cta3-shape-rounded vc_cta3-align-left vc_cta3-color-blue vc_cta3-icons-on-border vc_cta3-icon-size-md vc_cta3-icons-in-box vc_cta3-icons-top">
                    <div className="vc_cta3-icons">
                      <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                        <div className="vc_icon_element-inner vc_icon_element-color-juicy_pink vc_icon_element-have-style-inner vc_icon_element-size-md vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-white">
                          <span className="vc_icon_element-icon fas fa-exclamation-circle" />
                        </div>
                      </div>
                    </div>
                    <div className="vc_cta3_content-container">
                      <div className="vc_cta3-content">
                        <header className="vc_cta3-content-header" />
                        <p>
                          <strong> channel update</strong>: Disney and
                          Charter Communications have reached a resolution on
                          their carrier issue as of September 11, 2023. ESPN,
                          Disney Channel, and ABC are among the Disney-owned
                          networks that  TV subscribers may once again
                          view. Customers of  TV Select and  TV
                          Select Plus will have access to ESPN+, while
                          subscribers of  TV Select will also soon
                          obtain subscriptions to the ad-supported Disney+ plan.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Bundles"
                  id="Bundles"
                  className="section_anchor "
                  data-anchor="Bundles"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2>Get  TV, internet, and phone bundles</h2>
                  </div>
                </div>
                <div className="vc-table-scrolling scrolling-table">
                  <div className="grid-x">
                    <div className="cell vc-table-scrolling__table-container">
                      <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                        <tbody>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Package{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Price{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Download speeds up to{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Channel count{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Phone plan{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Details{" "}
                            </th>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet + TV Select{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $109.98/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              300 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Add unlimited nationwide calls for $19.99/mo.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet Ultra + TV Select{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $129.98/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              500 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Add unlimited nationwide calls for $19.99/mo.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet Gig + TV Select{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $149.98/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              1,000 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Add unlimited nationwide calls for $19.99/mo.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      Does your household require services like phone service to
                      contact your grandparents, cable TV for the parents, and
                      internet for the youngsters to browse TikTok? The packages
                      offered by  have you covered.
                    </p>
                    <p>
                      With download rates of up to 1,000 Mbps,  Internet
                      is fast enough for family movie evenings or video chats.
                      In addition to the 125+ TV channels that  TV
                      already offers, you may purchase extra channel packs to
                      expand your selection of sports and entertainment.
                      Additionally,  Voice provides reasonable add-ons
                      such as countrywide and international phone coverage if
                      you want landline phone service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="_Voice"
                  id="_Voice"
                  className="section_anchor "
                  data-anchor="_Voice"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> Voice</h2>
                  </div>
                </div>
                <div className="vc-table-scrolling scrolling-table">
                  <div className="grid-x">
                    <div className="cell vc-table-scrolling__table-container">
                      <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                        <tbody>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Package{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Price{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Download speeds up to{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Channel count{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Details{" "}
                            </th>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet Ultra + Voice{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $69.98/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              500 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet Gig + Voice{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $89.98/mo. for 24 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              1,000 Gbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet Ultra + TV Select + Voice{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $129.97/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              500 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        Data effective as of publish date. Offers and
                        availability may vary by location and are subject to
                        change.
                      </em>
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      The sole phone service that comes with a  bundle
                      is  Voice, which offers unlimited national
                      calling. In addition to your existing package, you may
                      obtain unlimited nationwide phone coverage for just $19.99
                      per month. Additionally, features like caller ID, call
                      filtering, and three-way calling are yours to have.
                    </p>
                    <p>
                      The majority of other  products can have Voice
                      added to them; however, the chart above just displays a
                      small selection of your possibilities. The $69.98 per
                      month, if it's available where you live. The Internet
                      Ultra + Voice combo is an excellent option for clients
                      seeking high-speed internet access. Additionally, you may
                      upgrade to the $129.97/mo. triple play if you'd want to
                      add a cable TV subscription.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Deals"
                  id="Deals"
                  className="section_anchor "
                  data-anchor="Deals"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> package deals</h2>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      Get the best  deals with a  service
                      bundle:
                    </p>
                    <ul>
                      <li>
                        Get affordable wireless phone service with{" "}
                        <Link to=""> Mobile</Link>.
                      </li>
                      <li>
                        Get a free year of <Link to="">Peacock Premium</Link>{" "}
                        with a  TV plan.
                      </li>
                      <li>Get a free internet modem with an internet plan.</li>
                    </ul>
                    <p>
                      Check out our <Link to=""> deals</Link> page for
                      the latest offers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <section className="vc_cta3-container">
                  <div className="vc_general vc_cta3 vc_cta3-style-outline vc_cta3-shape-rounded vc_cta3-align-left vc_cta3-color-blue vc_cta3-icons-on-border vc_cta3-icon-size-md vc_cta3-icons-in-box vc_cta3-icons-top">
                    <div className="vc_cta3-icons">
                      <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                        <div className="vc_icon_element-inner vc_icon_element-color-juicy_pink vc_icon_element-have-style-inner vc_icon_element-size-md vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-white">
                          <span className="vc_icon_element-icon fa fa-lightbulb-o" />
                        </div>
                      </div>
                    </div>
                    <div className="vc_cta3_content-container">
                      <div className="vc_cta3-content">
                        <header className="vc_cta3-content-header" />
                        <h3>
                          Want to learn more about ’s bundle options?
                        </h3>
                        <p>
                          Visit our <Link to=""> bundles review</Link>{" "}
                          for a full breakdown of the provider’s bundling
                          packages.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Compare"
                  id="Compare"
                  className="section_anchor "
                  data-anchor="Compare"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2>Compare  internet plans</h2>
                  </div>
                </div>
                <div className="vc-table-scrolling scrolling-table">
                  <div className="grid-x">
                    <div className="cell vc-table-scrolling__table-container">
                      <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                        <tbody>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Packages{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Price{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Download speeds{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Upload speeds up to{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Details{" "}
                            </th>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               Internet®{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Starting at $49.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Up to 300 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              10 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               Internet® Ultra{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Starting at $69.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Up to 500 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              20 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               Internet® Gig{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Starting at $89.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Up to 1,000 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              35 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        Data effective as of publish date. Offers and
                        availability may vary by location and are subject to
                        change.
                      </em>
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      <Link to="">’s internet plans</Link> offer
                      fantastic speeds and are a terrific value for subscribers.
                      For most families,  Internet's top internet speeds
                      of 300 Mbps will be a perfect fit. With a maximum download
                      speed of 500 Mbps,  Internet Ultra is ideal for
                      families with several internet users and gamers alike.
                    </p>
                    <p>
                      What happens if you require the finest of the best? With
                      its lightning-fast 1,000 Mbps download speeds, 
                      Internet Gig is the only option you need to consider.
                    </p>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="_TV"
                  id="_TV"
                  className="section_anchor "
                  data-anchor="_TV"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> TV: more channel customization, lower cost</h2>
                  </div>
                </div>
                <div className="vc-table-scrolling scrolling-table">
                  <div className="grid-x">
                    <div className="cell vc-table-scrolling__table-container">
                      <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                        <tbody>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Package{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Price{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Channel count{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Details{" "}
                            </th>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               TV® Select{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $59.99/mo.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      <Link to=""> TV Select</Link> is ’s main
                      TV package and it comes with 125+ network and basic cable
                      channels. In our annual{" "}
                      <Link to="">Editor’s Choice awards</Link>
                      ,We called TV . Choose the most affordable cable
                      TV package available; it will even outperform streaming
                      options like Hulu + Live TV and YouTube TV.
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> plans and channels</h2>
                  </div>
                </div>
                <div className="vc-table-scrolling scrolling-table">
                  <div className="grid-x">
                    <div className="cell vc-table-scrolling__table-container">
                      <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                        <tbody>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Channel package{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Popular channels{" "}
                            </th>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               TV Select ($59.99/mo.){" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              ABC, CNN, Lifetime, Paramount Network{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Entertainment View ($12.00/mo.){" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Cooking Channel, Disney XD, Military History
                              Channel, Ovation{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Sports View ($6.00/mo.){" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              ESPNews, Golf Channel, NFL RedZone{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Latino View ($12.00/mo.){" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Fox Deportes, beIN SPORTS, Telemundo{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              HBO Max ($15.00/mo.){" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              HBO Max subscription, HBO Family, HBO Zone{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              SHOWTIME ($10.00/mo.){" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              SHOWTIME, SHOWTIME Family Zone, Showtime Showcase{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              STARZ® ($9.00/mo.){" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              STARZ, STARZ Cinema, STARZEncore{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      If you want more channels, you can add channel packs on
                      top of your base  TV Select subscription.
                    </p>
                    <p>
                      The Entertainment View pack ($12 per month) offers great
                      value for families with channels like Disney XD and
                      Cooking Channel, while ’s premium cable packs
                      include <Link to="">SHOWTIME®</Link>&nbsp;and{" "}
                      <Link to="">HBO®</Link>.&nbsp;Sports fans should Sign up
                      for the $6/month Sports View channel bundle in the spring,
                      which offers Fox Sports 2, the ACC Network, NFL Redzone,
                      and other must-see sports networks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Bundle_features"
                  id="Bundle_features"
                  className="section_anchor "
                  data-anchor="Bundle_features"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2>Get more with  bundle features</h2>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      Numerous other features are also included with your
                       package. What you need know about the top bundle
                      add-ons from  is provided below.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <div className="row ">
                  <div className="columns small-12 medium-24 large-6">
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1565}
                            height={880}
                            src="../app/uploads/CTV_contract-buyout.jpg"
                            className="vc_single_image-img attachment-large"
                            alt="Closeup of a person with a pen signing a contract"
                            decoding="async"
                            title="CTV_contract-buyout"
                            fetchpriority="high"
                            srcSet="https://www.cabletv.com/app/uploads/CTV_contract-buyout.jpg 1565w, https://www.cabletv.com/app/uploads/CTV_contract-buyout-1115x627.jpg 1115w, https://www.cabletv.com/app/uploads/CTV_contract-buyout-1536x864.jpg 1536w"
                            sizes="(max-width: 1565px) 100vw, 1565px"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-6">
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h3>Contract buyout</h3>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          If your current internet provider has costly early
                          termination fees,  can lend a helping hand.
                           will cover up to $500 in early termination
                          fees to help you switch to .
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="row ">
                  <div className="columns small-12 medium-24 large-6">
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={947}
                            height={508}
                            src="../app/uploads/Xfinity-Hotspot-Map.png"
                            className="vc_single_image-img attachment-large"
                            alt="A map of hotspots in downtown Seattle, powered by Google."
                            decoding="async"
                            title="Xfinity Hotspot Map"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-6">
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h3> WiFi hotspots</h3>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                           Internet customers get free access to
                          ’s nationwide{" "}
                          <Link to="">Out-of-Home WiFi network</Link>. If you
                          need internet access while traveling, you can connect
                          to a  access point for free internet service.
                           has more than 530,000 nationwide hotspots at
                          locations like coffee shops and parks.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="row ">
                  <div className="columns small-12 medium-24 large-6">
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1565}
                            height={880}
                            src={Tv_APP}
                            className="vc_single_image-img attachment-large"
                            alt="On Demand streaming title cards on the  app"
                            decoding="async"
                            title="CTV_App"
                            // src="https://www.cabletv.com/app/uploads/CTV_App-1565x880.jpeg 1565w, https://www.cabletv.com/app/uploads/CTV_App-1115x627.jpeg 1115w, https://www.cabletv.com/app/uploads/CTV_App-1536x864.jpeg 1536w, https://www.cabletv.com/app/uploads/CTV_App-1600x900.jpeg 1600w, https://www.cabletv.com/app/uploads/CTV_App.jpeg 2048w"
                            sizes="(max-width: 1565px) 100vw, 1565px"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-6">
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h3> TV app</h3>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Get the full  experience with the{" "}
                          <Link to=""> TV app</Link>, which offers live
                          TV and on-demand programming anytime. You can install
                          the  TV on devices like smart TVs,
                          smartphones, and tablets.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2>All  packages</h2>
                  </div>
                </div>
                <div className="vc-table-scrolling scrolling-table">
                  <div className="grid-x">
                    <div className="cell vc-table-scrolling__table-container">
                      <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                        <tbody>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Package{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Price{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Download speeds up to{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Channel count{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Phone plan{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Details{" "}
                            </th>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet + Voice{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $69.98/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              300 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Unlimited nationwide calls{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet + TV Select{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $109.98/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              300 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet Ultra + TV Select{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $129.98/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              500 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet Gig + TV Select{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $149.98/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              1,000 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet Ultra + TV Select + Voice{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $149.97/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              500 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Unlimited nationwide calls{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Internet Gig + TV Select + Voice{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              $169.97/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              1,000 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Unlimited nationwide calls{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        Data effective as of publish date. Offers and
                        availability may vary by location and are subject to
                        change.
                      </em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="FAQ"
                  id="FAQ"
                  className="section_anchor "
                  data-anchor="FAQ"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> packages FAQ</h2>
                  </div>
                </div>
                <section className="section">
                  <div className="row align-left">
                    <div className="margin--bottom-15">
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt0"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt0-minus faq-opt0-plus faq-opt0-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt0-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt0-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>What are the different  packages?</h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt0-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                             has one <Link to="">TV</Link> package, one
                            voice package, and three{" "}
                            <Link to="">internet plans</Link>. You can bundle
                            these services together to save on your 
                            bill.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt1"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt1-minus faq-opt1-plus faq-opt1-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt1-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt1-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>What is the cheapest  package?</h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt1-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            The cheapest  packages are{" "}
                            <Link to=""> Internet</Link> ($49.99
                            monthly) and <Link to=""> TV Select</Link>{" "}
                            ($59.99 monthly).
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt2"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt2-minus faq-opt2-plus faq-opt2-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt2-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt2-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>
                                Does  give senior citizen discounts?
                              </h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt2-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                             doesn’t have senior citizen discounts. But
                            if you have family members who receive Supplement
                            Security Income, you can apply for the{" "}
                            <Link to=""> Internet Assist</Link> program.
                            This internet plan offers 30 Mbps download speeds
                            for $17.99 per month.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt3"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt3-minus faq-opt3-plus faq-opt3-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt3-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt3-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>
                                Does  have deals for existing customers?
                              </h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt3-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            The finest offerings from  for current users
                            are those from  Mobile, which gives 
                            internet users access to lower-cost cell phone
                            plans.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt4"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt4-minus faq-opt4-plus faq-opt4-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt4-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt4-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>How much does the  bundle cost?</h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt4-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            For the first twelve months, the  package
                            costs between $69.98 and $169.97 each month. The
                            precise cost of the  package is determined
                            by elements such as your internet package, extras,
                            and services.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt5"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt5-minus faq-opt5-plus faq-opt5-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt5-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt5-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkto="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>Is  TV free with internet?</h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt5-text"
                          className="toggle-option__section"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            No,  TV is not free with internet.{" "}
                            <Link to=""> TV Select</Link> will add
                            $59.99 to your monthly bill.
                          </p>
                          <p>
                            You may, however, be more familiar with 
                            One, a deal that offers  Internet users a
                            year of complimentary  Mobile service.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Disclaimer"
                  id="Disclaimer"
                  className="section_anchor "
                  data-anchor="Disclaimer"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      <strong>Disclaimer</strong>
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        <strong>† Internet:</strong> Offer is eligible
                        for qualifying residential customers who have not
                        subscribed to any services within the last 30 days and
                        who have no outstanding obligations to Charter. It is a
                        limited-time offer that is subject to modification. The
                        promotional price for  Internet ® is $49.99 per
                        month; regular rates resume after the first of the year.
                        Additional taxes, fees, and surcharges apply; they are
                        subject to change during and after the promotional time;
                        additional services, equipment, and installation/network
                        activation are extra. Terms in general: Internet:
                        Dependent on a wired connection, speed may differ per
                        address. Wireless coverage varies. All relevant service
                        terms and conditions apply to the services, and they are
                        subject to change. Some regions might not have access to
                        all services. There are restrictions. To find out what
                        is available, enter your address. ©2022 Charter:
                        Communications.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        <strong> Internet Ultra:</strong> This offer is
                        intended for qualifying residential customers who have
                        not subscribed to any services within the last 30 days
                        and who have no outstanding obligations to Charter. It
                        is a limited-time offer that is subject to change. The
                        promotional pricing for  Internet® Ultra is
                        $69.99 per month; regular prices will apply after year
                        1. Extra costs apply for taxes, fees, and surcharges,
                        which are subject to change both during and after the
                        promotional period. Additional services, equipment,
                        installation/network activation, and fees are also
                        extra. Terms in general: NETWORK: Speed varies by
                        address and is dependent on a wired connection. Wireless
                        coverage varies. All relevant service terms and
                        conditions apply to the services, and they are subject
                        to change. Not all places have access to all services.
                        There are restrictions. Input your address to find out
                        what's available. ©2022 Charter: Communications.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        <strong> Internet Gig:</strong>This offer is
                        intended for qualifying residential customers who have
                        not subscribed to any services within the last 30 days
                        and who have no outstanding obligations to Charter. It
                        is a limited-time offer that is subject to change. The
                        promotional pricing for  Internet® Ultra is
                        $69.99 per month; regular prices will apply after year
                        1. Extra costs apply for taxes, fees, and surcharges,
                        which are subject to change both during and after the
                        promotional period. Additional services, equipment,
                        installation/network activation, and fees are also
                        extra. Terms in general: NETWORK: Speed varies by
                        address and is dependent on a wired connection. Wireless
                        coverage varies. All relevant service terms and
                        conditions apply to the services, and they are subject
                        to change. Not all places have access to all services.
                        There are restrictions. Input your address to find out
                        what's available. ©2022 Charter: Communications.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        <strong> TV Select:</strong>Offer is eligible
                        for qualifying residential customers who have not
                        subscribed to any services within the last 30 days and
                        who have no outstanding obligations to Charter. It is a
                        limited-time offer that is subject to modification. The
                        promotional pricing for  TV® Select is $59.99
                        per month; regular rates resume after the first of the
                        year. Taxes, taxes, and surcharges (broadcast surcharge
                        up to $21.00/mo) are not included in the price and are
                        subject to change both during and after the special
                        time. Other costs include equipment,
                        installation/network activation, and additional
                        services. Terms in general: TV: TV equipment must be
                        purchased; fees may be incurred. Availability of
                        channels and HD content varies according to service
                        level. Some TV shows may require account credentials in
                        order to view them online. Services are available
                        subject to all relevant service terms and conditions and
                        are subject to change. Not all places have access to all
                        services. There are restrictions. Type your address
                        here.Offer is eligible for qualifying residential
                        customers who have not subscribed to any services within
                        the last 30 days and who have no outstanding obligations
                        to Charter. It is a limited-time offer that is subject
                        to modification. The promotional pricing for 
                        TV® Select is $59.99 per month; regular rates resume
                        after the first of the year. Taxes, taxes, and
                        surcharges (broadcast surcharge up to $21.00/mo) are not
                        included in the price and are subject to change both
                        during and after the special time. Other costs include
                        equipment, installation/network activation, and
                        additional services. Terms in general: TV: TV equipment
                        must be purchased; fees may be incurred. Availability of
                        channels and HD content varies according to service
                        level. Some TV shows may require account credentials in
                        order to view them online. Services are available
                        subject to all relevant service terms and conditions and
                        are subject to change. Not all places have access to all
                        services. There are restrictions. Type your address
                        here.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        <strong> Voice:</strong> Offer is eligible for
                        qualifying residential customers who have not subscribed
                        to any services within the last 30 days and who have no
                        outstanding obligations to Charter. It is a limited-time
                        offer that is subject to modification. The promotional
                        pricing for  TV® Select is $59.99 per month;
                        regular rates resume after the first of the year. Taxes,
                        taxes, and surcharges (broadcast surcharge up to
                        $21.00/mo) are not included in the price and are subject
                        to change both during and after the special time. Other
                        costs include equipment, installation/network
                        activation, and additional services. Terms in general:
                        TV: TV equipment must be purchased; fees may be
                        incurred. Availability of channels and HD content varies
                        according to service level. Some TV shows may require
                        account credentials in order to view them online.
                        Services are available subject to all relevant service
                        terms and conditions and are subject to change. Not all
                        places have access to all services. There are
                        restrictions. Type your address here.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        <strong> Internet + TV Select + Voice:</strong>{" "}
                        Temporary promotion; subject to alteration; available to
                        eligible residential clients who haven't enrolled to any
                        services throughout the last 30 days and who don't
                        unfinished business with Charter. Select  TV®
                        Promotional pricing is $59.99 per month; regular prices
                        resume after a year. 1. The special price for 
                        Internet® is $49.99 per month; regular After year 1,
                        rates take effect. Broadcast Voice®: It is $14.99 a
                        month. when combined. Fees, taxes, and other charges
                        (broadcast additional price of up to $21.00 per month;
                        subject to change both during and following the time of
                        promotion; equipment, setup/network activation, and
                        other Services cost more. Terms in general: Internet
                        speed: determined by wired link and might differ
                        depending on the address. wireless velocities may
                        differ. TV: Requires TV equipment; fees may apply.
                        Availability of channels and HD programs according to
                        the degree of offering.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        <strong> Internet + TV Select:</strong>{" "}
                        Temporary promotion; subject to alteration; available to
                        eligible residential clients who haven't enrolled to any
                        services throughout the last 30 days and who don't
                        unfinished business with Charter. Select  TV®
                        Promotional pricing is $59.99 per month; regular prices
                        resume after a year. 1. The special price for 
                        Internet® is $49.99 per month; regular After year 1,
                        rates take effect. Broadcast Voice®: It is $14.99 a
                        month. when combined. Fees, taxes, and other charges
                        (broadcast additional price of up to $21.00 per month;
                        subject to change both during and following the time of
                        promotion; equipment, setup/network activation, and
                        other Services cost more. Terms in general: Internet
                        speed: determined by wired link and might differ
                        depending on the address. wireless velocities may
                        differ. TV: Requires TV equipment; fees may apply.
                        Availability of channels and HD programs according to
                        the degree of offering.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        <strong> Internet + Voice:</strong>Temporary
                        promotion; subject to alteration; available to eligible
                        residential clients who haven't enrolled to any services
                        throughout the last 30 days and who don't unfinished
                        business with Charter. Select  TV® Promotional
                        pricing is $59.99 per month; regular prices resume after
                        a year. 1. The special price for  Internet® is
                        $49.99 per month; regular After year 1, rates take
                        effect. Broadcast Voice®: It is $14.99 a month. when
                        combined. Fees, taxes, and other charges (broadcast
                        additional price of up to $21.00 per month; subject to
                        change both during and following the time of promotion;
                        equipment, setup/network activation, and other Services
                        cost more. Terms in general: Internet speed: determined
                        by wired link and might differ depending on the address.
                        wireless velocities may differ. TV: Requires TV
                        equipment; fees may apply. Availability of channels and
                        HD programs according to the degree of offering.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        <strong>‡Mi Plan Latino:</strong>Temporary promotion;
                        subject to alteration; available to eligible residential
                        clients who haven't enrolled to any services throughout
                        the last 30 days and who don't unfinished business with
                        Charter. Select  TV® Promotional pricing is
                        $59.99 per month; regular prices resume after a year. 1.
                        The special price for  Internet® is $49.99 per
                        month; regular After year 1, rates take effect.
                        Broadcast Voice®: It is $14.99 a month. when combined.
                        Fees, taxes, and other charges (broadcast additional
                        price of up to $21.00 per month; subject to change both
                        during and following the time of promotion; equipment,
                        setup/network activation, and other Services cost more.
                        Terms in general: Internet speed: determined by wired
                        link and might differ depending on the address. wireless
                        velocities may differ. TV: Requires TV equipment; fees
                        may apply. Availability of channels and HD programs
                        according to the degree of offering.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        ◊A client must order and install a qualified promotion
                        in order to be eligible for the Contract Buyout Program;
                        offers not accessible anywhere. Offer accessible to
                        eligible clients exclusively who do not owe any money to
                        The charter. The Early will decide the amount of
                        payment. Termination Charge on the last invoice from the
                        prior supplier, with a maximum of $500. For Purchase
                        Agreement criteria, visit .com/buyout.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        **Wired connection based speed. The available Internet
                        speeds are subject to change based on an address. For
                        Gig speed, a modem with gig capability is necessary. Go
                        to .net/modem to view a list of modems that
                        support gigabit speeds.
                      </em>
                    </p>
                  </div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Bundles;
