import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isshow, setisShow] = useState(false);
  return (
    <header className="ctv-header">
      <div className="row">
        <div className="columns">
          <Link to="/" className="ctv-logo">
            <img
              data-perfmatters-preload=""
              src="changeimage/Cyberlink-01.png"
              alt="CableTV.com"
              style={{ height: "75px", width: "250px" }}
              loading="lazy"
            />
          </Link>
        </div>
        <div className="columns small-6 large-7 has-menu">
          <nav role="navigation" className="main-menu">
            <label htmlFor="toggle-menu">
              <div
                className="toggle-mobile-nav"
                aria-controls="main-menu__container"
                title="Toggle Menu"
              >
                <span className="bars" />
              </div>
              <label>
                <input type="checkbox" name="toggle-menu" id="toggle-menu" />
                <div className="main-menu__container">
                  <form
                    className="searchform"
                    action="https://www.cabletv.com/"
                    role="search"
                    data-search-form=""
                  >
                    <label htmlFor="s">
                      <span className="show-for-sr">Search</span>
                    </label>
                    <input
                      type="search"
                      aria-label="Search Text"
                      name="s"
                      placeholder="Enter Search Term or Zip Code"
                      defaultValue=""
                      maxLength={100}
                    />
                    <button type="submit">
                      <img
                        data-perfmatters-preload=""
                        src="app/themes/coolwhip-child/dist/images/pages/generic/search.svg"
                        alt="Submit Search"
                        loading="lazy"
                      />
                    </button>
                  </form>
                  <ul className="main-menu__ul">
                    <li
                      id="menu-item-107339"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-107339"
                    >
                      <Link to="/">Home</Link>
                    </li>
                    <li
                      id="menu-item-107341"
                      className="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-107341"
                    >
                      <Link
                        onClick={() => setisShow(!isshow)}
                        to="/shopinternet"
                      >
                        Services
                      </Link>
                      <ul
                        style={{ display: isshow && "block" }}
                        className={`vertical menu `}
                      >
                        <li
                          id="menu-item-103787"
                          className="menu-item menu-item-type-custom menu-item-object-custom menu-item-103787"
                        >
                          <Link to="/shopinternet">
                            Shop  Internet
                          </Link>
                        </li>
                        <li
                          id="menu-item-104164"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-104164"
                        >
                          <Link to="/shoptv">Shop  TV</Link>
                        </li>
                        <li
                          id="menu-item-105373"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-105373"
                        >
                          <Link to="/Deal"> Deals</Link>
                        </li>
                        <li
                          id="menu-item-107668"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-107668"
                        >
                          <Link to="/Bundles"> BUNDLE</Link>
                        </li>
                        <li
                          id="menu-item-94780"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-94780"
                        >
                          <Link to="/MOBILEPLANS">
                             MOBILE PLANS
                          </Link>
                        </li>
                        <li
                          id="menu-item-107139"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-107139"
                        >
                          <Link to="/cahnnel">
                             CHANNEL LINEUP
                          </Link>
                        </li>
                        <li
                          id="menu-item-94783"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-94783"
                        >
                          <Link to="/voice"> Voice Plans</Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/internetreview">
                             Internet Review
                          </Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/Tvreview"> Tv review</Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/Cheapestinternet">
                            Cheapest  Internet
                          </Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/bundlesreview">
                             Bundles Review
                          </Link>
                        </li>
                        <li
                          id="menu-item-120894"
                          className="menu-item menu-item-type-post_type menu-item-object-page menu-item-120894"
                        >
                          <Link to="/mobilereview">
                             Mobile Review
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li
                      id="menu-item-69722"
                      className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children menu-item-69722"
                    >
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>{" "}
                </div>
              </label>
            </label>
          </nav>
        </div>
        <div className="columns show-for-large large-3 header-search">
          <form
            className="searchform"
            action="https://www.cabletv.com/"
            role="search"
            data-search-form=""
          >
            <label htmlFor="s">
              <span className="show-for-sr">Search</span>
            </label>
            <input
              type="search"
              aria-label="Search Text"
              name="s"
              placeholder="Enter Search Term or Zip Code"
              defaultValue=""
              maxLength={100}
            />
            <button type="submit">
              <img
                data-perfmatters-preload=""
                src="app/themes/coolwhip-child/dist/images/pages/generic/search.svg"
                alt="Submit Search"
                loading="lazy"
              />
            </button>
          </form>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
