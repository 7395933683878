import React, { useState } from "react";
import "./contact.css";
import axios from "axios";
const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handlechange = (e) => {
    const { name, value } = e.target;
    setData((pre) => ({
      ...pre,
      [name]: value,
    }));
  };
  const handlesubmit = (e) => {
    e.preventDefault();
    console.log(data, "data");
    const Fromdata = new FormData();
    Fromdata.append("name", data.name);
    Fromdata.append("email", data.email);
    Fromdata.append("message", data.message);
    axios
      .post("https://api.dev-nuh.xyz/api/contact", Fromdata)
      .then((res) => {
        console.log(res, "response");
        if(res.data.status === true){
          alert(res.data.message)
          setData({
            name: "",
            email: "",
            message: "",
          })
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="contact container">
      <form>
        <div className="form">
          <div className="form-txt">
            <h1>Contact Us</h1>
            <span>
              As you might expect of a company that began as a high-end
              interiors contractor, we pay strict attention.
            </span>
            <h3>Email</h3>
            <p>
              <p style={{ fontWeight: "bold" }}>
                Click more detail & information
              </p>
              <a
                href="mailto:cyberlink-pro.com"
                // target="_blank"
                // rel="noreferrer"
              >
                cyberlink-pro.com
              </a>
            </p>
          </div>
          <div className="form-details">
            <input
              onChange={handlechange}
              type="text"
              name="name"
              value={data?.name}
              id="name"
              placeholder="Name"
              required=""
            />
            <input
              type="email"
              name="email"
              value={data?.email}
              id="email"
              onChange={handlechange}
              placeholder="Email"
              required=""
            />
            <textarea
              name="message"
              value={data?.message}
              id="message"
              cols={52}
              rows={7}
              onChange={handlechange}
              placeholder="Message"
              required=""
              defaultValue={""}
            />
            <button onClick={handlesubmit}>SEND MESSAGE</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Contact;
