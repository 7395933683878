import React from 'react'
import { Link } from 'react-router-dom'
const ShopTv = () => {
  return (
    <>
  <div id="main-content" className="main-wrap page" role="main">
    <article
      className="main-content post-103623 page type-page status-publish has-post-thumbnail hentry"
      id="post-103623"
    >
      <div className="grid-x expanded collapse">
        <div className="cell small-12">
          <section
            className="hero hero--generic hero--has-components"
            style={{ background: "#1d1d49" }}
          >
            <div className="row align-center">
              <div className="columns small-12 large-10">
                <h1 className="entry-title"> TV Packages</h1>
              </div>
            </div>
            <div className="row align-center">
              <div className="columns small-12 large-12">
                <div className="hero-components ">
                  <div className="row full-width-hero expanded collapse large-padding hide-for-small-only">
                    <div className="columns small-12 medium-12 large-6">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <ul>
                            <li style={{ marginBottom: 0, textAlign: "left" }}>
                              Up to 228+ channels with  add-on packs
                            </li>
                            <li style={{ marginBottom: 0, textAlign: "left" }}>
                              Comes with free Peacock Premium
                            </li>
                            <li style={{ marginBottom: 0, textAlign: "left" }}>
                              Watch on the go with ’s TV app
                            </li>
                            <li style={{ marginBottom: 0, textAlign: "left" }}>
                              No cable box requirements
                            </li>
                          </ul>
                          <p
                            className="h4"
                            style={{ paddingTop: 25, textAlign: "left" }}
                          >
                            TV packages starting at
                          </p>
                          <p style={{ textAlign: "left" }}>
                            <span className="h2" style={{ color: "#ffffff" }}>
                              $59.99<span className="h4">/mo.</span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="columns small-12 medium-24 large-3">
                          <div className="vc_btn3-container  text-left margin-bottom">
                            <Link
                              to="/contact"
                              className="button button--is-big  button--generic width--standard"
                              rel="nofollow noopener"
                            >
                              View Plans
                              <span className="show-for-sr"> for </span>
                            </Link>
                          </div>
                        </div>
                        <div className="columns small-12 medium-24 large-9" />
                      </div>
                      <div className="vc_empty_space" style={{ height: 32 }}>
                        <span className="vc_empty_space_inner" />
                      </div>
                    </div>
                    <div className="columns small-12 medium-12 large-6">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p>
                            <img
                              className="avatar alignnone wp-image-62562"
                              src="../app/uploads/CTV_app-directresponse-tv.jpg"
                              alt="A family watching TV together"
                              width={600}
                              height={400}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row full-width-hero expanded collapse large-padding hide-for-medium">
                    <div className="columns small-12 medium-12 large-6">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p
                            className="h4"
                            style={{ paddingTop: 5, textAlign: "center" }}
                          >
                            TV packages starting at
                          </p>
                          <p style={{ textAlign: "center" }}>
                            <span className="h2" style={{ color: "#ffffff" }}>
                              $59.99<span className="h4">/mo.</span>
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="vc_btn3-container text-center margin-bottom">
                        <Link
                          to="/contact"
                          className="button button--is-big  button--generic width--standard"
                          rel="nofollow noopener"
                        >
                          View Plans
                          <span className="show-for-sr"> for </span>
                        </Link>
                      </div>
                      <div className="vc_empty_space" style={{ height: 32 }}>
                        <span className="vc_empty_space_inner" />
                      </div>
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <ul>
                            <li style={{ marginBottom: 0, textAlign: "left" }}>
                              Up to 228+ channels with  add-on packs
                            </li>
                            <li style={{ marginBottom: 0, textAlign: "left" }}>
                              Comes with free Peacock Premium
                            </li>
                            <li style={{ marginBottom: 0, textAlign: "left" }}>
                              Watch on the go with ’s TV app
                            </li>
                            <li style={{ marginBottom: 0, textAlign: "left" }}>
                              No cable box requirements
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="vc_empty_space" style={{ height: 32 }}>
                        <span className="vc_empty_space_inner" />
                      </div>
                    </div>
                    <div className="columns small-12 medium-12 large-6">
                      <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                          <p>
                            <img
                              className="avatar alignnone wp-image-62562"
                              src="../app/uploads/CTV_-directresponse-tv.jpg"
                              alt="A family watching TV together"
                              width={600}
                              height={400}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="row expanded collapse">
        <div className="column small-12">
          <section className="section--cta-sticky" id="cta-sticky-container">
            <div className="row expanded collapsed align-center-middle">
              <div className="columns small-12 medium-10 cta-sticky--column-padding-restrict">
                <div className="cta-sticky" data-component="Call To Action">
                  <div className="row expanded collapsed align-center-middle">
                    <div className="columns small-12 show-for-small-only cta-sticky__button-area text-center no-padding-left-right">
                      <Link
                        className="button button--generic button--is-big button--is-bold button--fills-container"
                        to="/contact"
                        rel="nofollow"
                      >
                        Order Online
                      </Link>
                    </div>
                    <div className="columns small-8 show-for-medium no-padding-left">
                      <div className="cta-sticky__heading">
                        <h2 className="text--is-light">Get  now </h2>
                      </div>
                    </div>
                    <div className="columns small-4 show-for-medium no-padding-right">
                      <Link
                        className="button button--generic button--is-short-unlimited button--is-big button--is-bold float-right"
                        rel="nofollow"
                        to="/contact"
                      >
                        Order Online
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </article>
  </div>
  <div className="row align-center large-padding">
    <div className="columns small-12 large-10 bg-white large-padding-full">
      <div className="post-wrap">
        <div className="row">
          <div className="columns small-12 medium-12 large-4">
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2 style={{ fontSize: "1.5rem" }}>TV Select Signature</h2>
              </div>
            </div>
            <p
              style={{ fontSize: 36, textAlign: "left" }}
              className="vc_custom_heading"
            >
              <b>$59.99/mo.</b>
            </p>
            <div className="vc_empty_space" style={{ height: 15 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <ul>
                  <li>150+ channels</li>
                  <li>Includes local and basic cable channels</li>
                  <li>Channels include NBC, FX, and Magnolia Network</li>
                </ul>
              </div>
            </div>
            <div className="vc_btn3-container  text-left margin-bottom">
              <Link
                to="/contact"
                className="button button--is-big  button--generic width--standard"
                rel="nofollow noopener"
              >
                View Plan
              </Link>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
          <div className="columns small-12 medium-12 large-4">
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2 style={{ fontSize: "1.5rem" }}>
                  TV Select Signature + Entertainment View
                </h2>
              </div>
            </div>
            <p
              style={{ fontSize: 36, textAlign: "left" }}
              className="vc_custom_heading"
            >
              <b>$71.99/mo.</b>
            </p>
            <div className="vc_empty_space" style={{ height: 15 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <ul>
                  <li>205+ channels</li>
                  <li>Adds 80+ basic cable channels</li>
                  <li>Channels include Disney XD, Cooking Channel, and OWN</li>
                </ul>
              </div>
            </div>
            <div className="vc_btn3-container  text-left margin-bottom">
              <Link
                to="/contact"
                className="button button--is-big  button--generic width--standard"
                rel="nofollow noopener"
              >
                View Plan
              </Link>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
          <div className="columns small-12 medium-12 large-4">
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2 style={{ fontSize: "1.5rem" }}>
                  TV Select Signature + Sports View + Entertainment View
                </h2>
              </div>
            </div>
            <p
              style={{ fontSize: 36, textAlign: "left" }}
              className="vc_custom_heading"
            >
              <b>$77.99/mo.</b>
            </p>
            <div className="vc_empty_space" style={{ height: 15 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <ul>
                  <li>228+ channels</li>
                  <li>Adds premium live sports networks</li>
                  <li>
                    Channels include NFL RedZone, ESPNews, Outdoor Channel
                  </li>
                </ul>
              </div>
            </div>
            <div className="vc_btn3-container  text-left margin-bottom">
            <Link
                to="/contact"
                className="button button--is-big  button--generic width--standard"
                rel="nofollow noopener"
                
              >
                View Plan
              </Link>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
        </div>
        <div className="row vc_custom_1655852548078">
          <div className="columns small-12 medium-12 large-12">
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p>
                Enter your zip code below to learn more about  TV plans.
                </p>
                <form
                  className="zip-finder zip-finder--inline"
                  data-form-location="Body"
                  data-abide=""
                  noValidate=""
                  data-zip-finder=""
                >
                  <div className="zip-finder__wrapper">
                    <div className="zip-finder__fields">
                      <div className="zip-finder__input">
                        <input
                          type="tel"
                          name="zipcode"
                          required="required"
                          placeholder="Zip code"
                          autoComplete="off"
                          maxLength={5}
                          pattern="[0-9]{5}"
                        />
                        <span className="form-error">
                          Please enter a valid zip code.
                        </span>
                      </div>
                      <div className="zip-finder__button">
                        <button
                          className="button button--has-icon-on-right button--hide-spinner"
                          type="submit"
                        >
                          <h4 className="zip-finder__button__header">Find</h4>
                          <div className="button__icon">
                            <svg>
                              <use xlinkto="#pin" />
                            </svg>
                          </div>
                          <div
                            className="spinner"
                            role="alert"
                            aria-live="assertive"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <section className="vc_cta3-container">
              <div className="vc_general vc_cta3 vc_cta3-style-outline vc_cta3-shape-rounded vc_cta3-align-left vc_cta3-color-blue vc_cta3-icons-on-border vc_cta3-icon-size-md vc_cta3-icons-in-box vc_cta3-icons-top">
                <div className="vc_cta3-icons">
                  <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                    <div className="vc_icon_element-inner vc_icon_element-color-juicy_pink vc_icon_element-have-style-inner vc_icon_element-size-md vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-white">
                      <span className="vc_icon_element-icon fas fa-exclamation-circle" />
                    </div>
                  </div>
                </div>
                <div className="vc_cta3_content-container">
                  <div className="vc_cta3-content">
                    <header className="vc_cta3-content-header" />
                    <p>
                      <strong> channel update</strong>: Disney and Charter Communications have reached a resolution on their carrier issue as of September 11, 2023. ESPN, Disney Channel, and ABC are among the Disney-owned networks that  TV subscribers may once again view. Customers of  TV Select and  TV Select Plus will have access to ESPN+, while subscribers of  TV Select will also soon obtain subscriptions to the ad-supported Disney+ plan.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <span
              name="Packages_and_prices"
              id="Packages_and_prices"
              className="section_anchor "
              data-anchor="Packages_and_prices"
            />
            <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
              <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line" />
              </span>
              <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line" />
              </span>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2> TV packages</h2>
              </div>
            </div>
            <div className="vc-table-scrolling scrolling-table">
              <div className="grid-x">
                <div className="cell vc-table-scrolling__table-container">
                  <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                    <tbody>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                          Package{" "}
                        </th>
                        <th className="vc-table-scrolling__cell scrolling-table__cell ">
                          Price{" "}
                        </th>
                        <th className="vc-table-scrolling__cell scrolling-table__cell ">
                          Channel count{" "}
                        </th>
                        <th className="vc-table-scrolling__cell scrolling-table__cell "></th>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                           TV® Select Signature{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          $59.99/mo.{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          150+ channels{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                        <Link
                to="/contact"
                            className="button button--generic button--is-auto-width button--is-big"
                            rel="nofollow noopener"
                          >
                            View plan
                          </ Link>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p>
                With 's TV selections, you may pay for the channels you want. Other TV providers could overfill their TV plans with nameless channels to bolster their portfolios. The entry-level TV package offered by  is called Select Signature, and it includes more than 150 local and basic cable channels to accommodate all viewing preferences.
                </p>
                <p>
                Do you want to see the newest comedies? There is TNT, Comedy Central, TBS, and so on. And if the big game is underway? Your bases are covered there with ESPN, Fox Sports, and all the other big networks.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <span
              name="Channel_lineups"
              id="Channel_lineups"
              className="section_anchor "
              data-anchor="Channel_lineups"
            />
            <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
              <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line" />
              </span>
              <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line" />
              </span>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2>
                   TV Select Signature + Sports View + Entertainment
                  View
                </h2>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p>
                Let's face it: premium cable channels and live sports are two of the best reasons to purchase cable TV.
                </p>
                <p>
                While  TV Select Signature is certainly good, you may increase the number of channels you get by adding extra channel packs to your bundle. You may get both basic cable and premium cable channels, such as Cooking Channel, with each  add-on bundle.{" "}
                  <Link to="">Max™</Link>, and{" "}
                  <Link to="">SHOWTIME®</Link>.
                </p>
              </div>
            </div>
            <div className="vc-table-scrolling scrolling-table">
              <div className="grid-x">
                <div className="cell vc-table-scrolling__table-container">
                  <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                    <tbody>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                          Channel package{" "}
                        </th>
                        <th className="vc-table-scrolling__cell scrolling-table__cell ">
                          Popular channels{" "}
                        </th>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                           TV Select Signature ($59.99/mo.){" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          ABC, CNN, Lifetime, Paramount Network{" "}
                        </td>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                          Entertainment View ($12.00/mo.){" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          Cooking Channel, Disney XD, Military History Channel,
                          Ovation{" "}
                        </td>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                          Sports View ($7.00/mo.){" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          ESPNews, Golf Channel, NFL RedZone{" "}
                        </td>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                          Latino View ($12.00/mo.){" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          Fox Deportes, beIN SPORTS, Telemundo{" "}
                        </td>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                          Max ($16.00/mo.){" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          Max subscription, HBO Family, HBO Zone{" "}
                        </td>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                          SHOWTIME ($10.00/mo.){" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          SHOWTIME, SHOWTIME Family Zone, Showtime Showcase{" "}
                        </td>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                          STARZ® ($9.00/mo.){" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          STARZ, STARZ Cinema, STARZEncore{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <section className="vc_cta3-container">
              <div className="vc_general vc_cta3 vc_cta3-style-outline vc_cta3-shape-rounded vc_cta3-align-left vc_cta3-color-blue vc_cta3-icons-on-border vc_cta3-icon-size-md vc_cta3-icons-in-box vc_cta3-icons-top">
                <div className="vc_cta3-icons">
                  <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                    <div className="vc_icon_element-inner vc_icon_element-color-juicy_pink vc_icon_element-have-style-inner vc_icon_element-size-md vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-white">
                      <span className="vc_icon_element-icon fa fa-television" />
                    </div>
                  </div>
                </div>
                <div className="vc_cta3_content-container">
                  <div className="vc_cta3-content">
                    <header className="vc_cta3-content-header" />
                    <h3>Should I get  cable TV service?</h3>
                    <p>
                      Check out our full{" "}
                      <Link to="">
                         cable TV review
                      </Link>{" "}
                      to learn more about ’s TV options and costs.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <span
              name="Bundles"
              id="Bundles"
              className="section_anchor "
              data-anchor="Bundles"
            />
            <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
              <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line" />
              </span>
              <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line" />
              </span>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2> TV Select Signature + Entertainment View</h2>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p>
                  For the best deals, consider bundling  TV with{" "}
                  <Link to=""> Internet</Link>,{" "}
                  <Link to=""> Mobile</Link>, or phone service.
                  With a <Link to=""> bundle</Link>,You will receive a handy one bill along with a bundle discount. Additionally, 's internet services provide high download speeds that may accommodate any home's requirements.
                </p>
              </div>
            </div>
            <div className="vc-table-scrolling scrolling-table">
              <div className="grid-x">
                <div className="cell vc-table-scrolling__table-container">
                  <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                    <tbody>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                          Package{" "}
                        </th>
                        <th className="vc-table-scrolling__cell scrolling-table__cell ">
                          Price{" "}
                        </th>
                        <th className="vc-table-scrolling__cell scrolling-table__cell ">
                          Download speeds up to{" "}
                        </th>
                        <th className="vc-table-scrolling__cell scrolling-table__cell ">
                          Channel count{" "}
                        </th>
                        <th className="vc-table-scrolling__cell scrolling-table__cell ">
                          Phone plan{" "}
                        </th>
                        <th className="vc-table-scrolling__cell scrolling-table__cell ">
                          Details{" "}
                        </th>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                           Internet 300 Mbps +  TV Select
                          Signature{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          $109.98/mo. for 12 months{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          300 Mbps{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          150+ channels{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          Add for $14.99/mo.{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                          <Link
                            to="/contact"
                            className="button button--generic button--is-auto-width button--is-big"
                            rel="nofollow noopener"
                           
                          >
                            View plan
                          </Link>{" "}
                        </td>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                           Internet 500 Mbps +  TV Select
                          Signature{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          $129.98/mo. for 12 months{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          500 Mbps{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          150+ channels{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          Add for $14.99/mo.{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                        <Link
                            to="/contact"
                            className="button button--generic button--is-auto-width button--is-big"
                            rel="nofollow noopener"
                            
                          >
                            View plan
                          </Link>{" "}
                        </td>
                      </tr>
                      <tr className="vc-table-scrolling__row scrolling-table__row">
                        <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                           Internet Gig +  TV Select Signature +
                          Entertainment View{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          $161.98/mo. for 12 months{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          1,000 Mbps{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          205+ channels{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell ">
                          Add for $14.99/mo.{" "}
                        </td>
                        <td className="vc-table-scrolling__cell scrolling-table__cell has-button">
                        <Link
                            to="/contact"
                            className="button button--generic button--is-auto-width button--is-big"
                            rel="nofollow noopener"
                           
                          >
                            View plan
                          </Link>{" "}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <span
              name="Watch_when_you_want"
              id="Watch_when_you_want"
              className="section_anchor "
              data-anchor="Watch_when_you_want"
            />
            <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
              <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line" />
              </span>
              <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line" />
              </span>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2>Watch when you want with  TV</h2>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p>
                There's much more to 's TV package than just TV channels.
                  These are the additional features that come with your  TV subscription.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <div className="row ">
              <div className="columns small-12 medium-24 large-6">
                <div className="wpb_single_image wpb_content_element vc_align_left">
                  <figure className="wpb_wrapper vc_figure">
                    <div className="vc_single_image-wrapper   vc_box_border_grey">
                      <img
                        width={1565}
                        height={880}
                        src="../app/uploads/CTV_App-1565x880.png"
                        className="vc_single_image-img attachment-large"
                        alt="On Demand streaming title cards on the  app"
                        decoding="async"
                        title="CTV_App"
                        fetchpriority="high"
                        // srcSet="https://www.cabletv.com/app/uploads/CTV_App-1565x880.jpeg 1565w, https://www.cabletv.com/app/uploads/CTV_App-1115x627.jpeg 1115w, https://www.cabletv.com/app/uploads/CTV_App-1536x864.jpeg 1536w, https://www.cabletv.com/app/uploads/CTV_App-1600x900.jpeg 1600w, https://www.cabletv.com/app/uploads/CTV_App.jpeg 2048w"
                        sizes="(max-width: 1565px) 100vw, 1565px"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="columns small-12 medium-24 large-6">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3> TV app</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      The <Link to=""> TV App</Link>gives you access to watch your whole TV schedule as well as around 85,000 On Demand items on your iPad, smartphone, or smart TV.
                      on avoid becoming bored while traveling or staying at your in-laws' house, download the  TV App on your preferred streaming device and use it while watching at home.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
              <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line" />
              </span>
              <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line" />
              </span>
            </div>
            <div className="row ">
              <div className="columns small-12 medium-24 large-6">
                <div className="wpb_single_image wpb_content_element vc_align_left">
                  <figure className="wpb_wrapper vc_figure">
                    <div className="vc_single_image-wrapper   vc_box_border_grey">
                      <img
                        width={1121}
                        height={630}
                        src="../app/uploads/CTV_DVR.png"
                        className="vc_single_image-img attachment-large"
                        alt=" Arris DVR device on a shelf"
                        decoding="async"
                        title="CTV_DVR"
                        // srcSet="https://www.cabletv.com/app/uploads/CTV_DVR.png 1121w, https://www.cabletv.com/app/uploads/CTV_DVR-1115x627.png 1115w"
                        sizes="(max-width: 1121px) 100vw, 1121px"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="columns small-12 medium-24 large-6">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3> DVR</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    Do you want to watch programs later? You may record up to 100 episodes with Cloud DVR Plus ($9.99 per month), and you can watch them on the  TV app or TV.com."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <span
              name="Watch_how_you_want"
              id="Watch_how_you_want"
              className="section_anchor "
              data-anchor="Watch_how_you_want"
            />
            <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
              <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line" />
              </span>
              <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line" />
              </span>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2>Watch how you want with  TV</h2>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p>
                Although there are other methods to view  TV, you may always watch it by connecting a cable box to your TV. The  TV app may be accessed on several platforms such as Samsung smart TVs, Roku devices, and the Apple App Store. You are able to watch  TV on any screen after installing the  TV application.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <div className="row ">
              <div className="columns small-12 medium-24 large-6">
                <div className="wpb_single_image wpb_content_element vc_align_left">
                  <figure className="wpb_wrapper vc_figure">
                    <div className="vc_single_image-wrapper   vc_box_border_grey">
                      <img
                        width={1565}
                        height={880}
                        src="../app/uploads/CTV_AppGuide-1565x880.jpg"
                        className="vc_single_image-img attachment-large"
                        alt="Channel listings on the  TV app channel guide"
                        decoding="async"
                        title="CTV_AppGuide"
                        // srcSet="https://www.cabletv.com/app/uploads/CTV_AppGuide-1565x880.jpeg 1565w, https://www.cabletv.com/app/uploads/CTV_AppGuide-1115x627.jpeg 1115w, https://www.cabletv.com/app/uploads/CTV_AppGuide-1536x864.jpeg 1536w, https://www.cabletv.com/app/uploads/CTV_AppGuide-1600x900.jpeg 1600w, https://www.cabletv.com/app/uploads/CTV_AppGuide.jpeg 2048w"
                        sizes="(max-width: 1565px) 100vw, 1565px"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="columns small-12 medium-24 large-6">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>Free HD</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    It is not worth your effort to browse through the hazy standard-definition TV channels offered by other TV providers and streaming services. With the largest selection of HD channels available,  has you covered.  does not impose additional HD costs for the enjoyment of seeing television as it was intended to be watched; instead, it merely provides clear HD channels.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
              <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line" />
              </span>
              <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line" />
              </span>
            </div>
            <div className="row ">
              <div className="columns small-12 medium-24 large-6">
                <div className="wpb_single_image wpb_content_element vc_align_left">
                  <figure className="wpb_wrapper vc_figure">
                    <div className="vc_single_image-wrapper   vc_box_border_grey">
                      <img
                        width={1000}
                        height={562}
                        src="../app/uploads/CTV-why-people-still-pay-for-cable.png"
                        className="vc_single_image-img attachment-large"
                        alt=" TV remote control"
                        decoding="async"
                        title="CTV_-Remote-1"
                      />
                    </div>
                  </figure>
                </div>
              </div>
              <div className="columns small-12 medium-24 large-6">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>On-screen TV guide</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                    The most recent  TV receivers and channel guide make it simple to find the programming you want to watch.
                      The  on-screen TV guide neatly presents information like as programming, network logos, and your DVR library. The  remotes are user-friendly and intuitive.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <span
              name="FAQ"
              id="FAQ"
              className="section_anchor "
              data-anchor="FAQ"
            />
            <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
              <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line" />
              </span>
              <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line" />
              </span>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <h2> TV FAQ</h2>
              </div>
            </div>
            <section className="section">
              <div className="row align-left">
                <div className="margin--bottom-15">
                  <div className="toggle-option__container">
                  <Link
                            to=""
                      id="faq-opt0"
                      className="link--is-flex link--inherits-color"
                      data-toggle="faq-opt0-minus faq-opt0-plus faq-opt0-text"
                    >
                      <div className="toggle-option">
                        <div
                          id="faq-opt0-plus"
                          className="toggle-icon toggle--is-hidden"
                          data-toggler="toggle--is-shown"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-plus" />
                          </svg>
                        </div>
                        <div
                          id="faq-opt0-minus"
                          className="toggle-icon"
                          data-toggler="toggle--is-hidden"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-minus" />
                          </svg>
                        </div>
                        <div className="toggle-label">
                          <h3>How much is  TV a month?</h3>
                        </div>
                      </div>
                    </Link>
                    <div
                      id="faq-opt0-text"
                      className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                      data-toggler="toggle--is-hidden"
                    >
                      <p>
                      The monthly cost of  TV Select Signature is $59.99. For between $7.00 and $15.00 a month, you may buy extra basic and premium cable channel bundles.
                      </p>
                    </div>
                  </div>
                  <div className="toggle-option__container">
                    <Link
                      to=""
                      id="faq-opt1"
                      className="link--is-flex link--inherits-color"
                      data-toggle="faq-opt1-minus faq-opt1-plus faq-opt1-text"
                    >
                      <div className="toggle-option">
                        <div
                          id="faq-opt1-plus"
                          className="toggle-icon toggle--is-hidden"
                          data-toggler="toggle--is-shown"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-plus" />
                          </svg>
                        </div>
                        <div
                          id="faq-opt1-minus"
                          className="toggle-icon"
                          data-toggler="toggle--is-hidden"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-minus" />
                          </svg>
                        </div>
                        <div className="toggle-label">
                          <h3>What is the cheapest  TV package?</h3>
                        </div>
                      </div>
                    </Link>
                    <div
                      id="faq-opt1-text"
                      className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                      data-toggler="toggle--is-hidden"
                    >
                      <p>
                      The least expensive  TV bundle is TV Select Signature, which starts at{" "}
                        <span
                          data-sheets-value='{"1":2,"2":"$59.99/mo."}'
                          data-sheets-userformat='{"2":33554945,"3":{"1":0},"12":0,"28":1}'
                        >
                          $59.99/mo.
                        </span>{" "}
                        per month for{" "}
                        <span
                          data-sheets-value='{"1":2,"2":"150+"}'
                          data-sheets-userformat='{"2":33554945,"3":{"1":0},"12":0,"28":1}'
                        >
                          150+
                        </span>{" "}
                        HD channels including ESPN, Discovery Channel, CNN,
                        HGTV, Lifetime, TLC, and many more. You can bundle TV
                        Select Signature with{" "}
                        <Link
                      to="" rel="false">
                           Internet 300 Mbps
                        </Link>{" "}
                        for $109.98 per month.
                      </p>
                    </div>
                  </div>
                  <div className="toggle-option__container">
                   <Link
                      to=""
                      id="faq-opt2"
                      className="link--is-flex link--inherits-color"
                      data-toggle="faq-opt2-minus faq-opt2-plus faq-opt2-text"
                    >
                      <div className="toggle-option">
                        <div
                          id="faq-opt2-plus"
                          className="toggle-icon toggle--is-hidden"
                          data-toggler="toggle--is-shown"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-plus" />
                          </svg>
                        </div>
                        <div
                          id="faq-opt2-minus"
                          className="toggle-icon"
                          data-toggler="toggle--is-hidden"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-minus" />
                          </svg>
                        </div>
                        <div className="toggle-label">
                          <h3>
                            What is included in the  basic package?
                          </h3>
                        </div>
                      </div>
                    </Link>
                    <div
                      id="faq-opt2-text"
                      className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                      data-toggler="toggle--is-hidden"
                    >
                      <p>
                      The most basic TV bundle offered by  is called  TV Select Signature. Network and basic cable networks including ABC, Comedy Central, and Lifetime are included.
                      </p>
                    </div>
                  </div>
                  <div className="toggle-option__container">
                    <Link
                      to=""
                      id="faq-opt3"
                      className="link--is-flex link--inherits-color"
                      data-toggle="faq-opt3-minus faq-opt3-plus faq-opt3-text"
                    >
                      <div className="toggle-option">
                        <div
                          id="faq-opt3-plus"
                          className="toggle-icon toggle--is-hidden"
                          data-toggler="toggle--is-shown"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-plus" />
                          </svg>
                        </div>
                        <div
                          id="faq-opt3-minus"
                          className="toggle-icon"
                          data-toggler="toggle--is-hidden"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-minus" />
                          </svg>
                        </div>
                        <div className="toggle-label">
                          <h3>Is  TV free with internet?</h3>
                        </div>
                      </div>
                    </Link>
                    <div
                      id="faq-opt3-text"
                      className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                      data-toggler="toggle--is-hidden"
                    >
                      <p>
                      With internet access,  TV is not free. You will be required to pay a subscription fee in order to get  TV service.
                      </p>
                    </div>
                  </div>
                  <div className="toggle-option__container">
                   <Link
                      to=""
                      id="faq-opt4"
                      className="link--is-flex link--inherits-color"
                      data-toggle="faq-opt4-minus faq-opt4-plus faq-opt4-text"
                    >
                      <div className="toggle-option">
                        <div
                          id="faq-opt4-plus"
                          className="toggle-icon toggle--is-hidden"
                          data-toggler="toggle--is-shown"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-plus" />
                          </svg>
                        </div>
                        <div
                          id="faq-opt4-minus"
                          className="toggle-icon"
                          data-toggler="toggle--is-hidden"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-minus" />
                          </svg>
                        </div>
                        <div className="toggle-label">
                          <h3>What is 's most basic TV package?</h3>
                        </div>
                      </div>
                    </Link>
                    <div
                      id="faq-opt4-text"
                      className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                      data-toggler="toggle--is-hidden"
                    >
                      <p>
                      The most affordable TV bundle offered by  is called TV Select Signature, and it costs just $59.99 a month for more than 150 channels.
                      </p>
                    </div>
                  </div>
                  <div className="toggle-option__container">
                    <Link
                      to=""
                      id="faq-opt5"
                      className="link--is-flex link--inherits-color"
                      data-toggle="faq-opt5-minus faq-opt5-plus faq-opt5-text"
                    >
                      <div className="toggle-option">
                        <div
                          id="faq-opt5-plus"
                          className="toggle-icon toggle--is-hidden"
                          data-toggler="toggle--is-shown"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-plus" />
                          </svg>
                        </div>
                        <div
                          id="faq-opt5-minus"
                          className="toggle-icon"
                          data-toggler="toggle--is-hidden"
                        >
                          <svg className="image--icon-size-32 color--generic-color1">
                            <use xlinkto="#icon-minus" />
                          </svg>
                        </div>
                        <div className="toggle-label">
                          <h3>Are  TV and  cable the same?</h3>
                        </div>
                      </div>
                    </Link>
                    <div
                      id="faq-opt5-text"
                      className="toggle-option__section"
                      data-toggler="toggle--is-hidden"
                    >
                      <p>
                      Yes, you may use those names interchangeably because all of 's TV packages are cable-based.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="row">
          <div className="columns small-12 medium-12 large-12">
            <span
              name="Disclaimer"
              id="Disclaimer"
              className="section_anchor "
              data-anchor="Disclaimer"
            />
            <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
              <span className="vc_sep_holder vc_sep_holder_l">
                <span className="vc_sep_line" />
              </span>
              <span className="vc_sep_holder vc_sep_holder_r">
                <span className="vc_sep_line" />
              </span>
            </div>
            <div className="vc_empty_space" style={{ height: 32 }}>
              <span className="vc_empty_space_inner" />
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p>
                  <strong>Disclaimer</strong>
                </p>
              </div>
            </div>
            <div className="wpb_text_column wpb_content_element ">
              <div className="wpb_wrapper">
                <p style={{ fontSize: 12 }}>
                  <em>
                  The following terms and conditions apply to  TV Select Signature: limited time offer; subject to change; available only for qualifying residential customers who have not subscribed to any services during the preceding 30 days and who owe Charter nothing. The promotional pricing for  TV Select Signature is $59.99 per month; regular rates are applicable after the first year. Taxes, taxes, and surcharges (broadcast surcharge up to $23.20/mo) are not included in the advertised price and are subject to change both during and after the sale. Additional costs include equipment, installation/network activation, and other services. Terms in general: TV: TV equipment must be purchased; fees may be incurred. The availability of channels and HD content is contingent upon service level and geographic region. It might be necessary to have account credentials in order to view some TV shows online. Services are available subject to the relevant service terms and conditions, which are subject to modification. Not available services in
                  </em>
                </p>
                <p style={{ fontSize: 12 }}>
                  <em>
                   Internet + TV Select + Voice: Limited-time deal; eligible home subscribers only; subject to change

who did not sign up for any services in the preceding

30 days and who do not owe Charter any further money.

The promotional pricing for  TV® Select is $59.99/month; regular

After year 1, rates take effect. Price of the  Internet® promotion

is $49.99/month; after year 1, regular prices are applicable. Broadcast

Voice®: When packaged, the cost is $14.99/month. Fees, taxes, and

surcharges (up to $21.00/month for broadcast fees) farther and

liable to alter both during and beyond the promotional period;

equipment, setup/network activation, and other

Services cost more. Terms in general: Internet speed: determined by

wired link and might differ depending on the address. wireless velocities

may differ. TV: Requires TV equipment; fees may apply.

Availability of channels and HD programming
                  </em>
                </p>
                <p style={{ fontSize: 12 }}>
                  <em>
                  Limited-time deal from  Internet + TV Select; subject to change; available to eligible home customers who have

                    not signed up for any services in the 30 days before

                    Those who are not in default under the Charter. Broadcast

                    $59.99/month is the TV® Select offer price; regular prices apply.

                    after the first year. The promotion price for  Internet® is

                    $49.99/month; regular prices start the next year. Fees, taxes, and

                    surcharges (up to $21.00/month for broadcast fees) farther and

                    liable to alter both during and beyond the promotional period;

                    equipment, setup/network activation, and other

                    Services cost more. Terms in general: Internet speed: determined by

                    wired link and might differ depending on the address. wireless velocities

                    may differ. TV: Requires TV equipment; fees may apply.

                    Availability of channels and HD programs according to the degree of

                    service. Login information could
                  </em>
                </p>
                <p style={{ fontSize: 12 }}>
                  <em>
                   Internet 500 Mbps: When packaged, for a full year.
                    Offer is time-limited, subject to change, and available to eligible

                    residential clients not already enrolled in any

                    services throughout the last 30 days and who don't

                    unfinished business with Charter.

                  </em>
                </p>
                <p style={{ fontSize: 12 }}>
                  <em>
                  "Mi Plan Latino" is a limited-time offer that is subject to change. It is only eligible for qualifying residential customers who have not subscribed to any services during the last 30 days and who do not owe any money to Charter. Mi Plan Latino promotion: $39.99/month; regular pricing apply after the first year. Mi Plan Latino and Internet is $89.98 per month; regular charges are applicable after the first year. My Plan Latino, Internet, and Home Phone is $104.97 per month; after the first year, regular prices are applicable. The monthly cost of  TV Select with Latino View is $71.99; regular prices apply after the first year.
                    Extra and subject to change during and after the introductory time include taxes, fees, and surcharges (broadcast surcharge up to $21.00/mo); equipment and additional services; installation/network activation; and other expenses. Terms in general:
                    Internet speed is dependent on a connected line.
                  </em>
                </p>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </div>
  </div>
</>

  )
}

export default ShopTv