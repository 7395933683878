import React from "react";
import { Link } from "react-router-dom";
const Deal = () => {
  return (
    <>
      <div id="main-content" className="main-wrap page" role="main">
        <article
          className="main-content post-80493 page type-page status-publish has-post-thumbnail hentry"
          id="post-80493"
        >
          <div className="grid-x expanded collapse">
            <div className="cell small-12">
              <section
                className="hero hero--generic hero--has-components"
                style={{ background: "#1d1d49" }}
              >
                <div className="row align-center">
                  <div className="columns small-12 large-10">
                    <h1 className="entry-title">
                       Deals and Promotions
                    </h1>
                  </div>
                </div>
                <div className="row align-center">
                  <div className="columns small-12 large-12">
                    <div className="hero-components ">
                      <div className="row full-width-hero expanded collapse large-padding">
                        <div className="columns small-12 medium-12 large-6">
                          <div className="row ">
                            <div className="columns small-12 medium-24 large-12">
                              <div className="wpb_text_column wpb_content_element ">
                                <div className="wpb_wrapper">
                                  <ul>
                                    <li style={{ textAlign: "left" }}>
                                      Up to 125+ channels
                                    </li>
                                    <li style={{ textAlign: "left" }}>
                                      Free HD picture quality
                                    </li>
                                    <li style={{ textAlign: "left" }}>
                                      Download speeds up to 1000 Mbps (wireless
                                      speeds may vary)
                                    </li>
                                    <li style={{ textAlign: "left" }}>
                                      No extra fees or data caps
                                    </li>
                                    <li style={{ textAlign: "left" }}>
                                      Unlimited nationwide calling
                                    </li>
                                  </ul>
                                  <p
                                    className="h4"
                                    style={{
                                      paddingTop: 25,
                                      textAlign: "left",
                                    }}
                                  >
                                    Packages starting at
                                  </p>
                                  <p style={{ textAlign: "left" }}>
                                    <span
                                      className="h2"
                                      style={{ color: "#ffffff" }}
                                    >
                                      $49.99<span className="h4">/mo.</span>
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row ">
                            <div className="columns small-12 medium-24 large-3">
                              <div className="vc_btn3-container  text-left margin-bottom">
                                <Link
                                  to="/contact"
                                  className="button button--is-big  button--generic width--standard"
                                  rel="nofollow noopener"
                                >
                                  View plan
                                </Link>
                              </div>
                            </div>
                            <div className="columns small-12 medium-24 large-9" />
                          </div>
                          <div
                            className="vc_empty_space"
                            style={{ height: 32 }}
                          >
                            <span className="vc_empty_space_inner" />
                          </div>
                        </div>
                        <div className="columns small-12 medium-12 large-6">
                          <div className="wpb_text_column wpb_content_element ">
                            <div className="wpb_wrapper">
                              <p>
                                <img
                                  className="avatar alignnone wp-image-60466 size-full"
                                  src="../app/uploads/TV-Intenet-Deals-Family.jpg"
                                  alt="Family Watching TV and Using Internet"
                                  width={900}
                                  height={600}
                                />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className="row expanded collapse">
            <div className="column small-12">
              <section
                className="section--cta-sticky"
                id="cta-sticky-container"
              >
                <div className="row expanded collapsed align-center-middle">
                  <div className="columns small-12 medium-10 cta-sticky--column-padding-restrict">
                    <div className="cta-sticky" data-component="Call To Action">
                      <div className="row expanded collapsed align-center-middle">
                        <div className="columns small-12 show-for-small-only cta-sticky__button-area text-center no-padding-left-right">
                          <Link
                            className="button button--generic button--is-big button--is-bold button--fills-container"
                            to="/contact"
                            rel="nofollow"
                          >
                            Order Online
                          </Link>
                        </div>
                        <div className="columns small-8 show-for-medium no-padding-left">
                          <div className="cta-sticky__heading">
                            <h2 className="text--is-light">
                              Get  now{" "}
                            </h2>
                          </div>
                        </div>
                        <div className="columns small-4 show-for-medium no-padding-right">
                          <Link
                            className="button button--generic button--is-short-unlimited button--is-big button--is-bold float-right"
                            rel="nofollow"
                            to="/contact"
                          >
                            Order Online
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </article>
      </div>
      <div className="row align-center large-padding">
        <div className="columns small-12 large-10 bg-white large-padding-full">
          <div className="post-wrap">
            <div className="row">
              <div className="columns small-12 medium-12 large-4">
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_sep_color_blue">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                    <div className="vc_icon_element-inner vc_icon_element-color-juicy_pink vc_icon_element-have-style-inner vc_icon_element-size-md vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-white">
                      <span className="vc_icon_element-icon fa fa-wifi" />
                    </div>
                  </div>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3> Internet</h3>
                  </div>
                </div>
                <p
                  style={{ fontSize: 36, textAlign: "left" }}
                  className="vc_custom_heading"
                >
                  <b>$49.99/mo.</b>
                </p>
                <div className="vc_empty_space" style={{ height: 15 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Download speeds up to 300 Mbps</li>
                      <li>Stream on multiple devices</li>
                      <li>Free modem</li>
                    </ul>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    View Plan
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
              <div className="columns small-12 medium-12 large-4">
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_sep_color_blue">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                    <div className="vc_icon_element-inner vc_icon_element-color-juicy_pink vc_icon_element-have-style-inner vc_icon_element-size-md vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-white">
                      <span className="vc_icon_element-icon fa fa-television" />
                    </div>
                  </div>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>TV Select</h3>
                  </div>
                </div>
                <p
                  style={{ fontSize: 36, textAlign: "left" }}
                  className="vc_custom_heading"
                >
                  <b>$59.99/mo.</b>
                </p>
                <div className="vc_empty_space" style={{ height: 15 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul>
                      <li>125+ channels</li>
                      <li>Includes local and basic cable channels</li>
                      <li>Free Peacock Premium</li>
                    </ul>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    View Plan
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
              <div className="columns small-12 medium-12 large-4">
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_sep_color_blue">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                    <div className="vc_icon_element-inner vc_icon_element-color-juicy_pink vc_icon_element-have-style-inner vc_icon_element-size-md vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-white">
                      <span className="vc_icon_element-icon fa fa-plus" />
                    </div>
                  </div>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>Internet + TV Select</h3>
                  </div>
                </div>
                <p
                  style={{ fontSize: 36, lineHeight: 1, textAlign: "left" }}
                  className="vc_custom_heading"
                >
                  <b>$109.98/mo.</b>
                </p>
                <div className="vc_empty_space" style={{ height: 15 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <ul>
                      <li>Download speeds up to 300 Mbps</li>
                      <li>Includes CNN, ESPN, NBC, and more</li>
                      <li>Free Peacock Premium</li>
                    </ul>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    View Plan
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      Nobody like paying more for their internet or cable
                      service than is necessary. Whether you are a prospective
                      or existing  Customer, we have the most effective
                      methods to reduce your monthly Spend less on the 
                      bill.
                    </p>
                  </div>
                </div>
                <section className="vc_cta3-container">
                  <div className="vc_general vc_cta3 vc_cta3-style-outline vc_cta3-shape-rounded vc_cta3-align-left vc_cta3-color-blue vc_cta3-icon-size-md">
                    <div className="vc_cta3_content-container">
                      <div className="vc_cta3-content">
                        <header className="vc_cta3-content-header" />
                        <p>
                          <strong>Limited time offer:&nbsp;</strong>Customers
                          who use  One can save more than $400. Upon
                          enrolling in 300 Mbps  internet, you will
                          receive one line of unlimited mobile service and a
                          full year of advanced wifi.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2>Compare  deals</h2>
                  </div>
                </div>
                <div className="vc-table-scrolling scrolling-table">
                  <div className="grid-x">
                    <div className="cell vc-table-scrolling__table-container">
                      <table className="vc-table-scrolling__table scrolling-table__table margin-bottom">
                        <tbody>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                              Package{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Price{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Download speeds{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Channels{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell ">
                              Promotions{" "}
                            </th>
                            <th className="vc-table-scrolling__cell scrolling-table__cell "></th>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               Internet{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Starting at $49.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Up to 300 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              •90-day Peacock Premium subscription
                              <br />
                              •Free modem
                              <br />
                              •Up to $500 to cover fees if you switch providers◊
                              <br />
                              •No extra fees or data caps
                              <br />
                              •Advanced WiFi
                              <br />
                              •12 mos. of  Mobile{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               Internet® Ultra{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Starting at $69.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Up to 500 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              •90-day Peacock Premium subscription
                              <br />
                              •Free modem
                              <br />
                              •Up to $500 to cover fees if you switch providers◊
                              <br />
                              •No extra fees or data caps
                              <br />
                              •Advanced WiFi
                              <br />
                              •12 mos. of  Mobile
                              <br />
                              •Save up to $30/mo. on Internet Ultra and Internet
                              Gig for a limited time**†
                              <br />{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               Internet Gig{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Starting at $89.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Up to 1,000 Mbps{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              •90-day Peacock Premium subscription
                              <br />
                              •Free modem
                              <br />
                              •Up to $500 to cover fees if you switch providers◊
                              <br />
                              •No extra fees or data caps
                              <br />
                              •Advanced WiFi
                              <br />
                              •12 mos. of  Mobile
                              <br />
                              •Save up to $30/mo. on Internet Ultra and Internet
                              Gig for a limited time**†
                              <br />{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                          <tr className="vc-table-scrolling__row scrolling-table__row">
                            <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                               TV® Select{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              Starting at $59.99/mo. for 12 mos.{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              N/A{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              125+ channels{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              •12-month Peacock Premium subscription
                              <br />
                              •Up to $500 to cover fees if you switch providers◊
                              <br />
                              •Free HD
                              <br />
                              •Advanced WiFi
                              <br />
                              •12 mos. of  Mobile{" "}
                            </td>
                            <td className="vc-table-scrolling__cell scrolling-table__cell ">
                              <Link
                                to="/contact"
                                className="button button--generic button--is-auto-width button--is-big"
                                rel="nofollow noopener"
                              >
                                View plan
                              </Link>{" "}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        Data valid as of the publication date. Offers and
                        availability are subject to change and may differ
                        depending on the location.
                        <br />
                        **Offer dependent on region
                        <br />
                      </em>
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      Looking for more  deals and packages? Enter your
                      zip code below to view the services that  provides
                      in your region.
                    </p>
                    <form
                      className="zip-finder zip-finder--inline"
                      data-form-location="Body"
                      data-abide=""
                      noValidate=""
                      data-zip-finder=""
                    >
                      <div className="zip-finder__wrapper">
                        <div className="zip-finder__fields">
                          <div className="zip-finder__input">
                            <input
                              type="tel"
                              name="zipcode"
                              required="required"
                              placeholder="Zip code"
                              autoComplete="off"
                              maxLength={5}
                              pattern="[0-9]{5}"
                            />
                            <span className="form-error">
                              Please enter a valid zip code.
                            </span>
                          </div>
                          <div className="zip-finder__button">
                            <button
                              className="button button--has-icon-on-right button--hide-spinner"
                              type="submit"
                            >
                              <h4 className="zip-finder__button__header">
                                Find
                              </h4>
                              <div className="button__icon">
                                <svg>
                                  <use xlinkHref="#pin" />
                                </svg>
                              </div>
                              <div
                                className="spinner"
                                role="alert"
                                aria-live="assertive"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="_Deals_Features"
                  id="_Deals_Features"
                  className="section_anchor "
                  data-anchor="_Deals_Features"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2>Learn more about  deals’ features</h2>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      Customers of  are frequently eligible for special
                      pricing, discounts, bundles, and other incentives. An
                      outline of what  customers might anticipate is
                      provided below:
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="columns small-12 medium-24 large-3">
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={150}
                            height={150}
                            src="../app/uploads/CTV_VOD_icon.svg"
                            className="vc_single_image-img attachment-thumbnail"
                            alt="Video-On-Demand icon"
                            decoding="async"
                            title="CTV_VOD_icon"
                            srcSet="https://www.cabletv.com/app/uploads/CTV_VOD_icon.svg 150w, https://www.cabletv.com/app/uploads/CTV_VOD_icon.svg 1115w, https://www.cabletv.com/app/uploads/CTV_VOD_icon.svg 1565w, https://www.cabletv.com/app/uploads/CTV_VOD_icon.svg 1536w, https://www.cabletv.com/app/uploads/CTV_VOD_icon.svg 2048w, https://www.cabletv.com/app/uploads/CTV_VOD_icon.svg 60w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-9">
                    <div className="vc_empty_space" style={{ height: 32 }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h3>Promotional pricing and offers</h3>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                           is providing all of its clients with 3–12
                          months of Peacock Premium for a restricted period of
                          time, depending on the qualifying package.
                          Additionally, clients who enroll in  One will
                          receive a complimentary 12-month subscription to
                           Mobile when they purchase an internet
                          package.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="columns small-12 medium-24 large-3">
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={150}
                            height={150}
                            src="../app/uploads/CTV_wifi_icon.svg"
                            className="vc_single_image-img attachment-thumbnail"
                            alt="Wifi icon"
                            decoding="async"
                            title="CTV_wifi_icon"
                            srcSet="https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 150w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 1115w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 1565w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 1536w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 2048w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 60w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-9">
                    <div className="vc_empty_space" style={{ height: 32 }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h3> covers early termination fees</h3>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                           will give you up to $500 when you sign up for
                          an eligible package if you'd want to switch from
                          another provider that charges early termination
                          penalties.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="columns small-12 medium-24 large-3">
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={150}
                            height={150}
                            src="../app/uploads/CTV_router_icon.svg"
                            className="vc_single_image-img attachment-thumbnail"
                            alt="Router icon"
                            decoding="async"
                            title="CTV_router_icon"
                            srcSet="https://www.cabletv.com/app/uploads/CTV_router_icon.svg 150w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 1115w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 1565w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 1536w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 2048w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 60w"
                            sizes="(max-width: 150px) 100vw, 150px"
                          />
                        </div>
                      </figure>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-9">
                    <div className="vc_empty_space" style={{ height: 32 }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h3>No hidden fees</h3>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          All of ’s internet plans come with the promise
                          of no hidden fees or <Link to="">data caps</Link>, so
                          you can surf the web with peace of mind.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="_Discounts"
                  id="_Discounts"
                  className="section_anchor "
                  data-anchor="_Discounts"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> discounts</h2>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3> student discount</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                       does not provide a discount for students. On the
                      other hand, certain low-income residences could be
                      eligible for <Link to=""> Internet Assist</Link>,
                      which offers 30 Mbps speeds for as low as $17.99 a month.
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3> senior discount</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                       does not provide a discount for students. On the
                      other hand, certain low-income residences could be
                      eligible for <Link to=""> Internet Assist</Link>,
                      and will receive 30 Mbps speeds for as low as $17.99 a
                      month.
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3> military discount</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                       does not give discounts to military personnel.
                      Nonetheless, certain low-income houses can be eligible for{" "}
                      <Link to=""> Internet Assist</Link>, which gives
                      customers access to 30 Mbps speeds for as low as $17.99 a
                      month.
                    </p>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Free_Peacock_Premium"
                  id="Free_Peacock_Premium"
                  className="section_anchor "
                  data-anchor="Free_Peacock_Premium"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="row ">
                  <div className="columns small-12 medium-24 large-6">
                    <div className="vc_empty_space" style={{ height: 32 }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <h2>Free Peacock Premium</h2>
                      </div>
                    </div>
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          Customers who sign up for  TV Select (except
                          from those who only have the Basic tier or TV
                          Essentials package) are eligible for a complimentary{" "}
                          <Link to="" rel="false">
                            Peacock Premium
                          </Link>{" "}
                          subscription for 12 months. ’s internet
                          customers also get a taste of this deal with a 90-day
                          subscription.
                        </p>
                        <p>
                          You have the option to either cancel your Peacock
                          Premium subscription at the conclusion of the
                          introductory time or keep using it at $5.99 per month.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="columns small-12 medium-24 large-6">
                    <div className="wpb_single_image wpb_content_element vc_align_left">
                      <figure className="wpb_wrapper vc_figure">
                        <div className="vc_single_image-wrapper   vc_box_border_grey">
                          <img
                            width={1115}
                            height={1115}
                            src="../app/uploads/peacocktv.svg"
                            className="vc_single_image-img attachment-medium"
                            alt='" "'
                            decoding="async"
                            title="peacocktv"
                            srcSet="https://www.cabletv.com/app/uploads/peacocktv.svg 150w, https://www.cabletv.com/app/uploads/peacocktv.svg 1115w, https://www.cabletv.com/app/uploads/peacocktv.svg 1565w, https://www.cabletv.com/app/uploads/peacocktv.svg 1536w, https://www.cabletv.com/app/uploads/peacocktv.svg 2048w"
                            sizes="(max-width: 1115px) 100vw, 1115px"
                          />
                        </div>
                      </figure>
                    </div>
                    <div className="vc_btn3-container text-center margin-bottom">
                      <Link
                        to="/contact"
                        className="button button--is-big  button--generic width--standard"
                        rel="nofollow noopener"
                      >
                        Get this deal
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Contract_Buyout_Program"
                  id="Contract_Buyout_Program"
                  className="section_anchor "
                  data-anchor="Contract_Buyout_Program"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> Contract Buyout Program</h2>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                       will pay up to $500<sup>◊</sup> to cover your
                      early termination fee if you want to jump ship from your
                      current TV or internet provider. To qualify for the{" "}
                      <Link to="/contact" rel="noopener nofollow noreferrer">
                         Contract Buyout Program
                      </Link>
                      , you’ll just need to sign up for an eligible 
                      bundle with internet, TV, and/or phone service.
                    </p>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    Switch to 
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="Deals_for_existing_customers"
                  id="Deals_for_existing_customers"
                  className="section_anchor "
                  data-anchor="Deals_for_existing_customers"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> deals for existing customers</h2>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      Even if you’re a current{" "}
                      <Link to=""> Internet</Link> However, there are
                      still a number of promotions available to you from the
                      cable company.
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>Free cable internet modem</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      A FREE modem that links your house to the provider's cable
                      internet network is included with every  Internet
                      subscription.
                    </p>
                    <p>
                      Although it's generally advised to purchase your own
                      modem, 's FREE modem offer (which requires you to
                      return the device when you go) is a simple method to save
                      money if you don't already have one.
                    </p>
                    <p>
                      A new modem may cost anywhere from $60 to $120, whereas
                      the typical monthly rental fee for a modem is generally
                      between $5 and $15.
                    </p>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    Reach out for this deal
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3> Mobile</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      <Link to="/contact" data-brand="" rel="nofollow">
                        <span> Mobile</span>
                      </Link>{" "}
                      is a stand-alone  service that offers cell service
                      and mobile data options to  Internet members. The
                      greatest offers from  Mobile include savings of up
                      to $1,000 on brand-new phones like the Google Pixel 7 and
                      Samsung Galaxy Z Flip5.
                    </p>
                    <p>
                      If you currently have a  Internet subscription and
                      would like to switch mobile phone carriers, 
                      Mobile can help you save money. at example, 
                      Mobile offers unlimited data at $29.99 per line, or $14
                      for every gigabyte of data utilized by all of the phone
                      lines in your plan.
                    </p>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    Reach out for this deal
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h3>Apple TV 4K</h3>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      <Link to=""> TV</Link> subscribers can get the 32
                      GB <Link to="">Apple TV 4K</Link>Only $8.99 per month over
                      the course of a 20-month payment plan. Ultimately, that is
                      just a few pennies less than the standard cost of an Apple
                      TV 4K; however, installment plans may be advantageous if
                      you prefer not to part with a significant amount of money
                      all at once.
                    </p>
                    <p>
                      You can effortlessly access programming from  and
                      other networks using the Apple TV's auto-login function
                      without having to manually enter your cable login
                      information. Additionally, streaming apps from sites like
                      MaxTM, Netflix, and Hulu are available on the Apple TV.
                    </p>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    to="/contact"
                    className="button button--is-big  button--generic width--standard"
                    rel="nofollow noopener"
                  >
                    Reach out for this deal
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <span
                  name="_Internet_Assist_program"
                  id="_Internet_Assist_program"
                  className="section_anchor "
                  data-anchor="_Internet_Assist_program"
                />
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> Internet Assist program</h2>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p>
                      <Link to="/contact" rel="noopener nofollow noreferrer">
                         Internet Assist
                      </Link>{" "}
                      provides subsidized internet connection to low-income or
                      elderly users who meet the eligibility requirements. You
                      can expect a maximum download speed of 30 Mbps (wireless
                      speeds may vary) and a FREE modem rental when you use
                       Internet Assist.
                    </p>
                    <p>
                      Someone in your household must be registered in
                      Supplemental Security Income, the National School Lunch
                      Program (NSLP), or the NSLP's Community Eligibility
                      Provision in order for you to be eligible for 
                      Internet Assist.
                    </p>
                    <p>
                      If you qualify, we recommend you pair your 
                      Internet Assist plan with the FCC’s{" "}
                      <Link to="" rel="false">
                        Affordable Connectivity Program
                      </Link>
                      . Combining both programs basically gives you free
                      internet service.
                    </p>
                  </div>
                </div>
                <div className="vc_btn3-container  text-left margin-bottom">
                  <Link
                    className="button button--is-big  button--generic button--is-short-unlimited button--is-bold  width--standard"
                    to="/contact"
                    data-element="Button"
                    rel="nofollow"
                  >
                    Get internet assistance
                  </Link>
                </div>
                <div className="vc_empty_space" style={{ height: 32 }}>
                  <span className="vc_empty_space_inner" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <h2> deals FAQ</h2>
                  </div>
                </div>
                <section className="section">
                  <div className="row align-left">
                    <div className="margin--bottom-15">
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt0"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt0-minus faq-opt0-plus faq-opt0-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt0-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt0-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>
                                What kind of deals does  have right now?
                              </h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt0-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                             is now offering the  One bundle for
                            a limited time. This package entitles you to
                            Advanced WiFi and 12 months of  Mobile when
                            you sign up for a new  internet plan (speeds
                            beginning at 300 Mbps).
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt1"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt1-minus faq-opt1-plus faq-opt1-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt1-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt1-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>How do I get a better deal on ?</h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt1-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <ol>
                            <li>
                              Call ’s sales department at{" "}
                              <Link to="/contact">+1-833-694-9259</Link>
                              &nbsp;before your 12-month promotional price ends.
                            </li>
                            <li>
                              Tell  that you’re considering canceling
                              your TV or Internet service because your 
                              bill is too high.
                            </li>
                            <li>
                              Try to get redirected to ’s retention
                              department.
                            </li>
                            <li>
                              If you successfully reach the retention
                              department, you’ll typically qualify for one of
                              several offers: extended guarantees that lock in
                              your promotional price, service upgrades, or
                              discounts.
                            </li>
                            <li>
                              Accept the offer that you like the best and enjoy
                              your cheaper monthly bill.
                            </li>
                          </ol>
                          <p>
                            Read our{" "}
                            <Link to="/contact">
                               bill negotiation guide
                            </Link>{" "}
                            for more details.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to="/contact"
                          id="faq-opt2"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt2-minus faq-opt2-plus faq-opt2-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt2-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt2-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>What is the cheapest  TV package?</h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt2-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            The cheapest <Link to=""> TV</Link> package
                            is  TV® Select, which starts at $59.99
                            <sup>*</sup> per month for 12 months.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt3"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt3-minus faq-opt3-plus faq-opt3-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt3-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt3-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>
                                How can I get a discount on  Internet?
                              </h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt3-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            Families that qualify for subsidized internet access
                            due to poor income or senior status can take
                            advantage of the  Internet Assist program.
                          </p>
                          <p>
                            If any member of your family is enrolled in
                            Supplemental Security Income, the National School
                            Lunch Program (NSLP), or the NSLP's Community
                            Eligibility Provision, they must meet the
                            eligibility requirements.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt4"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt4-minus faq-opt4-plus faq-opt4-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt4-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt4-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>Does  have a student discount?</h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt4-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            Although  does not provide a
                            subscription-only discount for students, you may be
                            eligible for the  Internet Assist program if
                            your home is a participant in the National School
                            Lunch Program. For as little as $17.99 a month, you
                            can enjoy 30 Mbps internet speeds with 
                            Internet Assist.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt5"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt5-minus faq-opt5-plus faq-opt5-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt5-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt5-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>Does  have a senior discount?</h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt5-text"
                          className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                          data-toggler="toggle--is-hidden"
                        >
                          <p>
                            Although there isn't a senior-only discount offered
                            by , anyone 65 and over who receive
                            Supplemental Security Income may be eligible for the
                             Internet Assist program. For as little as
                            $17.99 a month, you can enjoy 30 Mbps download
                            speeds.
                          </p>
                        </div>
                      </div>
                      <div className="toggle-option__container">
                        <Link
                          to=""
                          id="faq-opt6"
                          className="link--is-flex link--inherits-color"
                          data-toggle="faq-opt6-minus faq-opt6-plus faq-opt6-text"
                        >
                          <div className="toggle-option">
                            <div
                              id="faq-opt6-plus"
                              className="toggle-icon toggle--is-hidden"
                              data-toggler="toggle--is-shown"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-plus" />
                              </svg>
                            </div>
                            <div
                              id="faq-opt6-minus"
                              className="toggle-icon"
                              data-toggler="toggle--is-hidden"
                            >
                              <svg className="image--icon-size-32 color--generic-color1">
                                <use xlinkHref="#icon-minus" />
                              </svg>
                            </div>
                            <div className="toggle-label">
                              <h3>Does  have a military discount?</h3>
                            </div>
                          </div>
                        </Link>
                        <div
                          id="faq-opt6-text"
                          className="toggle-option__section"
                          data-toggler="toggle--is-hidden"
                        >
                          <p> does not have a military discount.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                  <span className="vc_sep_holder vc_sep_holder_l">
                    <span className="vc_sep_line" />
                  </span>
                  <span className="vc_sep_holder vc_sep_holder_r">
                    <span className="vc_sep_line" />
                  </span>
                </div>
                <div
                  className="wpb_text_column wpb_content_element "
                  id="disclaimer"
                >
                  <div className="wpb_wrapper">
                    <p>
                      <strong>Disclaimers</strong>
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        * Offer applicable only for qualifying residential
                        customers who have not subscribed to any services within
                        the last 30 days and who do not owe Charter any money.
                        Limited period; subject to change. The promotional
                        pricing for  TV® Select is $59.99 per month;
                        regular prices will apply after the first year. Taxes,
                        taxes, and surcharges (broadcast surcharge up to
                        $21.00/mo.) are not included in the price and may vary
                        during and after the promotional time. Other costs
                        include equipment and additional services, installation
                        and network activation, and other expenses. Terms in
                        general: TV: Requires TV equipment; fees may apply.
                        Availability of HD and channel programming varies
                        according to service level. It might be necessary to
                        have account credentials in order to view some TV shows
                        online. Services are available subject to the relevant
                        service terms and conditions, which are subject to
                        modification. Certain regions may not have access to all
                        services. There are restrictions. Type your address
                        here.
                        <br />
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        ◊In order to be eligible for the Contract Buyout
                        Program, a client has to acquire and install a mix of
                        qualified Video and Voice and/or Internet services;
                        deals not available in all regions. Mobile services
                        provided by  are not qualified for the buyout
                        scheme. Offer only valid for eligible customers. only
                        those who do not owe anything to the Charter. Repayment
                        The Early Termination Fee on will decide the amount. the
                        last invoice from the former supplier, not more than
                        $550. Qualifications for Contract Buyout may be found at
                        buyout..com.
                      </em>
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        Peacock Premium offer: “You are only eligible to redeem
                        this Offer if you are a  TV (other than the
                        Basic tier or TV Essentials product) customer or
                         Internet customer (including the TV Essentials
                        product) and you: (i) are 18 years of age or older; (ii)
                        reside in the United States; (iii) provide Peacock with
                        a valid payment method; (iv) are a residential customer
                        with no outstanding obligation to ; and (v) are
                        not in seasonal suspend. Only one offer is permitted per
                         account, and only one login (main or standard
                        role) may be used to complete the offer's activation.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        Login credentials for a  account are needed to
                        assess eligibility. You might lose access to some NBC
                        content or be ineligible to start the Peacock Premium
                        12-month offer if you are an otherwise qualified
                         TV customer and your local NBC television
                        station is not being carried by . Eligibility
                        may be impacted by any service modifications.
                      </em>
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                        Subject to modification, this offer is valid from
                        December 8, 2021, to December 7, 2023 (the "Offer
                        Redemption Period"). This offer must be redeemed before
                        the last day of the offer redemption period or it will
                        become worthless after it has expired. The terms of this
                        agreement, the Peacock Terms of Use, which are available
                        at peacocktv.com/terms, and the Privacy Policy, which is
                        available at peacocktv.com/privacy, govern the
                        redemption of this offer and your use of Peacock both
                        during and after your Access Period.The terms and
                        conditions that apply to every applicable service apply
                        to  services. Certain places do not have access
                        to  services.
                      </em>
                    </p>
                    <em></em>
                  </div>
                  <em></em>
                </div>
                <em></em>
                <div className="wpb_text_column wpb_content_element ">
                  <em></em>
                  <div className="wpb_wrapper">
                    <em></em>
                    <p>
                      <em />
                    </p>
                    <p style={{ fontSize: 12 }}>
                      <em>
                         One is a limited-time offer that is subject to
                        change. It is only applicable for qualifying residential
                        customers who haven't subscribed to any services in the
                        last 30 days and who don't owe Charter any money. The
                        provision of services is contingent upon compliance with
                        all relevant service terms and conditions, which are
                        subject to modification. Not all places have access to
                        all services. There are restrictions in place. Internet
                        and Advanced WiFi: After the first year, standard
                        charges are applicable for both services. For Internet,
                        WiFi, and mobile service, standard charges are
                        applicable if the mobile line is not activated within 30
                        days and is kept active during the promotional period.
                        Additional services such as installation and equipment
                        may be charged separately. speed determined by a
                        connected cable. Please note that actual speeds may
                        differ.  MOBILE: Not all locations have service
                        available. Auto Pay and  Internet are necessary.
                        Others
                        <br />
                        Savings based on 12 months of  Mobile Unlimited
                        at no charge and 12 months of Advanced WiFi at no
                        charge.
                        <br />
                        Time-limited promotion. Customers of  TV who
                        meet the requirements can get a complimentary 12-month
                        subscription to Peacock Premium. price. Customers of
                         Internet who meet the requirements can Peacock
                        Premium for ninety days, free of charge. Moreover There
                        can be limitations on eligibility.  login
                        Credentials are needed to assess who is eligible for the
                        special deal. Combining promotional offers is not
                        permitted. Following the campaign time, Peacock Premium
                        price of Applying is $4.99/month (plus tax). Refund at
                        any time. Make an offer regional differences may exist
                        in availability and content. Eligibility may be impacted
                        by any service modifications. Current Peacock Premium
                        users might have to take extra procedures; specifics are
                        in the offer conditions.
                      </em>
                    </p>
                  </div>
                </div>
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper">
                    <p style={{ fontSize: 12 }}>
                      <em>
                        ...for a whole year when combined. This is a
                        limited-time deal that is subject to change. It is only
                        available to qualifying residential customers who
                        haven't subscribed to any services in the last 30 days
                        and who don't owe Charter anything.
                      </em>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="columns small-12 medium-12 large-12">
                <div className="wpb_text_column wpb_content_element ">
                  <div className="wpb_wrapper"></div>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </div>
    </>
  );
};

export default Deal;
