import React from 'react'
import { Link } from 'react-router-dom'
import first from '../uploads/ctv-Internet-Best-speeds.png'
import first1 from '../uploads/CTV-app-500Mbps.png'
import first2 from '../uploads/CTV_2-Online-150x150.png'
const ShopInternet = () => {
  return (
    <div id="main-content" className="main-wrap page" role="main">
  <article
    className="main-content post-103618 page type-page status-publish has-post-thumbnail hentry"
    id="post-103618"
  >
    <div className="grid-x expanded collapse">
      <div className="cell small-12">
        <section
          className="hero hero--generic hero--has-components"
          style={{ background: "#1d1d49" }}
        >
          <div className="row align-center">
            <div className="columns small-12 large-10">
              <h1 className="entry-title">
                 Internet Plans, Prices, and Packages
              </h1>
            </div>
          </div>
          <div className="row align-center">
            <div className="columns small-12 large-12">
              <div className="hero-components ">
                <div className="row full-width-hero expanded collapse large-padding">
                  <div className="columns small-12 medium-12 large-6">
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <ul>
                          <li style={{ marginBottom: 0, textAlign: "left" }}>
                            Get download speeds up to 1,000 Mbps
                          </li>
                          <li style={{ marginBottom: 0, textAlign: "left" }}>
                            Unlimited data
                          </li>
                          <li style={{ marginBottom: 0, textAlign: "left" }}>
                            No contracts
                          </li>
                          <li style={{ marginBottom: 0, textAlign: "left" }}>
                            Free access to ’s nationwide hotspot network
                          </li>
                          <li style={{ textAlign: "left" }}>Free modem</li>
                        </ul>
                        <p
                          className="h4"
                          style={{ paddingTop: 25, textAlign: "left" }}
                        >
                          Internet packages starting at
                        </p>
                        <p style={{ textAlign: "left" }}>
                          <span className="h2" style={{ color: "#ffffff" }}>
                            $49.99<span className="h4">/mo.</span>
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="columns small-12 medium-24 large-3">
                        <div className="vc_btn3-container  text-left margin-bottom">
                          <Link
                            to="/contact"
                            className="button button--is-big  button--generic width--standard"
                            rel="nofollow noopener"
                          >
                            View Plans
                            <span className="show-for-sr"> for </span>
                          </Link>
                        </div>
                      </div>
                      <div className="columns small-12 medium-24 large-9" />
                    </div>
                    <div className="vc_empty_space" style={{ height: 32 }}>
                      <span className="vc_empty_space_inner" />
                    </div>
                  </div>
                  <div className="columns small-12 medium-12 large-6">
                    <div className="wpb_text_column wpb_content_element ">
                      <div className="wpb_wrapper">
                        <p>
                          <img
                            className="avatar alignnone wp-image-62562"
                            src={first}
                            alt="Ethernet cable plugged into a router"
                            width={600}
                            height={400}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div className="row expanded collapse">
      <div className="column small-12">
        <section className="section--cta-sticky" id="cta-sticky-container">
          <div className="row expanded collapsed align-center-middle">
            <div className="columns small-12 medium-10 cta-sticky--column-padding-restrict">
              <div className="cta-sticky" >
                <div className="row expanded collapsed align-center-middle">
                  <div className="columns small-12 show-for-small-only cta-sticky__button-area text-center no-padding-left-right">
                    <Link
                      className="button button--generic button--is-big button--is-bold button--fills-container"
                      to="/contact"
                      rel="nofollow"
                    >
                      Order Online
                    </Link>
                  </div>
                  <div className="columns small-8 show-for-medium no-padding-left">
                    <div className="cta-sticky__heading">
                      <h2 className="text--is-light">Get  now </h2>
                    </div>
                  </div>
                  <div className="columns small-4 show-for-medium no-padding-right">
                    <Link
                      className="button button--generic button--is-short-unlimited button--is-big button--is-bold float-right"
                      rel="nofollow"
                      to="/contact"
                    >
                      Order Online
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div className="row align-center large-padding">
  <div className="columns small-12 large-10 bg-white large-padding-full">
    <div className="post-wrap">
      <div className="row">
        <div className="columns small-12 medium-12 large-4">
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2 style={{ fontSize: "1.5rem" }}> Internet</h2>
            </div>
          </div>
          <p
            style={{ fontSize: 36, textAlign: "left" }}
            className="vc_custom_heading"
          >
            <b>$49.99/mo.</b>
          </p>
          <div className="vc_empty_space" style={{ height: 15 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <ul>
                <li>Download speeds up to 300 Mbps</li>
                <li>Great for families</li>
                <li>Get a free modem</li>
              </ul>
            </div>
          </div>
          <div className="vc_btn3-container  text-left margin-bottom">
            <Link
              to="/contact"
              className="button button--is-big  button--generic width--standard"
              rel="nofollow noopener"
            >
              View Plan
            </Link>
          </div>
        </div>
        <div className="columns small-12 medium-12 large-4">
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2 style={{ fontSize: "1.5rem" }}> Internet® Ultra</h2>
            </div>
          </div>
          <p
            style={{ fontSize: 36, textAlign: "left" }}
            className="vc_custom_heading"
          >
            <b>$69.99/mo.</b>
          </p>
          <div className="vc_empty_space" style={{ height: 15 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <ul>
                <li>Download speeds up to 500 Mbps</li>
                <li>Best for gamers and large families</li>
                <li>Get a free modem</li>
              </ul>
            </div>
          </div>
          <div className="vc_btn3-container  text-left margin-bottom">
          <Link
              to="/contact"
              className="button button--is-big  button--generic width--standard"
              rel="nofollow noopener"
              target="_blank"
            >
              View Plan
            </Link>
          </div>
        </div>
        <div className="columns small-12 medium-12 large-4">
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2 style={{ fontSize: "1.5rem" }}> Internet Gig</h2>
            </div>
          </div>
          <p
            style={{ fontSize: 36, textAlign: "left" }}
            className="vc_custom_heading"
          >
            <b>$89.99/mo.</b>
          </p>
          <div className="vc_empty_space" style={{ height: 15 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <ul>
                <li>Download speeds up to 1,000 Mbps</li>
                <li>Get ’s best internet speeds</li>
                <li>Use fast upload speeds for smooth video calls</li>
              </ul>
            </div>
          </div>
          <div className="vc_btn3-container  text-left margin-bottom">
          <Link
              to="/contact"
              className="button button--is-big  button--generic width--standard"
              rel="nofollow noopener"
              target="_blank"
            >
              View Plan
            </Link>
          </div>
        </div>
      </div>
      <div className="row vc_custom_1655844463214">
        <div className="columns small-12 medium-12 large-12">
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p>
                Enter your zip code below to see ’s download speeds in
                your area.
              </p>
              <form
                className="zip-finder zip-finder--inline"
                data-form-location="Body"
                data-abide=""
                noValidate=""
                data-zip-finder=""
              >
                <div className="zip-finder__wrapper">
                  <div className="zip-finder__fields">
                    <div className="zip-finder__input">
                      <input
                        type="tel"
                        name="zipcode"
                        required="required"
                        placeholder="Zip code"
                        autoComplete="off"
                        maxLength={5}
                        pattern="[0-9]{5}"
                      />
                      <span className="form-error">
                        Please enter a valid zip code.
                      </span>
                    </div>
                    <div className="zip-finder__button">
                      <button
                        className="button button--has-icon-on-right button--hide-spinner"
                        type="submit"
                      >
                        <h4 className="zip-finder__button__header">Find</h4>
                        <div className="button__icon">
                          <svg>
                            <use xlinkHref="#pin" />
                          </svg>
                        </div>
                        <div
                          className="spinner"
                          role="alert"
                          aria-live="assertive"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-12 large-12">
          <span
            name="Prices_and_packages"
            id="Prices_and_packages"
            className="section_anchor "
            data-anchor="Prices_and_packages"
          />
          <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
            <span className="vc_sep_holder vc_sep_holder_l">
              <span className="vc_sep_line" />
            </span>
            <span className="vc_sep_holder vc_sep_holder_r">
              <span className="vc_sep_line" />
            </span>
          </div>
          <div className="vc_empty_space" style={{ height: 32 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2> internet prices and packages</h2>
            </div>
          </div>
          <div className="vc-table-scrolling scrolling-table">
            <div className="grid-x">
              <div className="cell vc-table-scrolling__table-container">
                <table className="vc-table-scrolling__table scrolling-table__table no-margin-bottom">
                  <tbody>
                    <tr className="vc-table-scrolling__row scrolling-table__row">
                      <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                        Packages{" "}
                      </th>
                      <th className="vc-table-scrolling__cell scrolling-table__cell ">
                        Price{" "}
                      </th>
                      <th className="vc-table-scrolling__cell scrolling-table__cell ">
                        Download speeds{" "}
                      </th>
                      <th className="vc-table-scrolling__cell scrolling-table__cell ">
                        Upload speeds up to{" "}
                      </th>
                      <th className="vc-table-scrolling__cell scrolling-table__cell "></th>
                    </tr>
                    <tr className="vc-table-scrolling__row scrolling-table__row">
                      <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                         Internet{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        Starting at $49.99/mo. for 12 mos.{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        Up to 300 Mbps{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        10 Mbps{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        <Link
                          to="/contact"
                          className="button button--generic button--is-auto-width button--is-big"
                          rel="nofollow noopener"
                        >
                          View plan
                        </Link>{" "}
                      </td>
                    </tr>
                    <tr className="vc-table-scrolling__row scrolling-table__row">
                      <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                         Internet® Ultra{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        Starting at $69.99/mo. for 12 mos.{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        Up to 500 Mbps{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        20 Mbps{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        <Link
                          to="/contact"
                          className="button button--generic button--is-auto-width button--is-big"
                          rel="nofollow noopener"
                        >
                          View plan
                        </Link>{" "}
                      </td>
                    </tr>
                    <tr className="vc-table-scrolling__row scrolling-table__row">
                      <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                         Internet Gig{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        Starting at $89.99/mo. for 12 mos.{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        Up to 1,000 Mbps{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        35 Mbps{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        <Link
                          to="/contact"
                          className="button button--generic button--is-auto-width button--is-big"
                          rel="nofollow noopener"
                          
                        >
                          View plan
                        </Link>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p style={{ fontSize: 12 }}>
                <em>
                Data valid as of the publication date. Offers and availability are subject to change and may differ depending on the location.
                </em>
              </p>
            </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p>
                ’s three internet packages combine affordability with
                performance, and they’re a fit for every budget or household.
                 internet prices without bundle start at a reasonable
                $49.99 per month.
              </p>
              <p>
                With the  Internet® plan, your family can surf the
                internet without buffering on the cheap.  Internet Ultra
                ups the ante for larger households who work and do school from
                home with download speeds of up to 500 Mbps. For speed demons
                who need the fastest internet,  Internet Gig offers
                speeds up to 1 Gbps.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-12 large-12">
          <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
            <span className="vc_sep_holder vc_sep_holder_l">
              <span className="vc_sep_line" />
            </span>
            <span className="vc_sep_holder vc_sep_holder_r">
              <span className="vc_sep_line" />
            </span>
          </div>
          <div className="vc_empty_space" style={{ height: 32 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2> TV and internet packages</h2>
            </div>
          </div>
          <div className="vc-table-scrolling scrolling-table">
            <div className="grid-x">
              <div className="cell vc-table-scrolling__table-container">
                <table className="vc-table-scrolling__table scrolling-table__table no-margin-bottom">
                  <tbody>
                    <tr className="vc-table-scrolling__row scrolling-table__row">
                      <th className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                        Packages{" "}
                      </th>
                      <th className="vc-table-scrolling__cell scrolling-table__cell ">
                        Price{" "}
                      </th>
                      <th className="vc-table-scrolling__cell scrolling-table__cell ">
                        Download speeds{" "}
                      </th>
                      <th className="vc-table-scrolling__cell scrolling-table__cell ">
                        Channel count{" "}
                      </th>
                      <th className="vc-table-scrolling__cell scrolling-table__cell "></th>
                    </tr>
                    <tr className="vc-table-scrolling__row scrolling-table__row">
                      <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                        Internet + TV Select{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        $109.98/mo. for 12 mos.{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        Up to 300 Mbps{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        125+ channels{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                      <Link
                          to="/contact"
                          className="button button--generic button--is-auto-width button--is-big"
                          rel="nofollow noopener"
                        
                        >
                          View plan
                        </Link>{" "}
                      </td>
                    </tr>
                    <tr className="vc-table-scrolling__row scrolling-table__row">
                      <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                        Internet Ultra + TV Select{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        $129.98/mo. for 12 mos.{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        Up to 500 Mbps{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        125+ channels{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                      <Link
                          to="/contact"
                          className="button button--generic button--is-auto-width button--is-big"
                          rel="nofollow noopener"
                          target="_blank"
                        >
                          View plan
                        </Link>{" "}
                      </td>
                    </tr>
                    <tr className="vc-table-scrolling__row scrolling-table__row">
                      <td className="vc-table-scrolling__cell scrolling-table__cell sticky-label">
                        Internet Gig + TV Select{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        $149.98/mo. for 12 mos.{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        Up to 1 Gbps{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                        125+ channels{" "}
                      </td>
                      <td className="vc-table-scrolling__cell scrolling-table__cell ">
                      <Link
                          to="/contact"
                          className="button button--generic button--is-auto-width button--is-big"
                          rel="nofollow noopener"
                        
                        >
                          View plan
                        </Link>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p style={{ fontSize: 12 }}>
                <em>
                Data valid as of the publication date. Offers and availability are subject to change and may differ depending on the location.
                </em>
              </p>
            </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p>
                <span style={{ fontWeight: 400 }}>
                Do you also need cable TV for your new internet plan? You're covered by  with an abundance of{" "}
                  <Link
                          to="/contact">bundles</Link>.{" "}
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                In addition to 's blazing-fast internet connections, you may receive 125+ channels with its TV and Internet bundles.
                  Furthermore, you may add extra channel packs to your account to access even more sports and entertainment content if you'd want to watch even more.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-12 large-12">
          <span
            name="Speeds"
            id="Speeds"
            className="section_anchor "
            data-anchor="Speeds"
          />
          <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
            <span className="vc_sep_holder vc_sep_holder_l">
              <span className="vc_sep_line" />
            </span>
            <span className="vc_sep_holder vc_sep_holder_r">
              <span className="vc_sep_line" />
            </span>
          </div>
          <div className="vc_empty_space" style={{ height: 32 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2>Get fast  internet speeds</h2>
            </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p>
               offers the perfect internet speed at the perfect price, regardless of your preference for fast internet or more affordable plans. Do you have any doubts regarding the internet speed you require? The ideal uses for each internet package are listed below.
              </p>
            </div>
          </div>
          <div className="row ">
            <div className="columns small-12 medium-24 large-4">
              <div className="wpb_single_image wpb_content_element vc_align_center">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={772}
                      height={772}
                      src={first}
                      className="vc_single_image-img attachment-medium"
                      alt="Speed dial showing 300 Mbps"
                      decoding="async"
                      title="_CTV-_-300Mbps"
                      fetchpriority="high"
                      // srcSet="https://www.cabletv.com/app/uploads/CTV-_-300Mbps.png 772w, https://www.cabletv.com/app/uploads/CTV-_-300Mbps-150x150.png 150w"
                      sizes="(max-width: 772px) 100vw, 772px"
                    />
                  </div>
                </figure>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3 style={{ textAlign: "center" }}> Internet</h3>
                  <h3 style={{ textAlign: "center" }}>(300 Mbps)</h3>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                    <strong>Common uses:</strong>
                  </p>
                  <ul>
                    <li>Watching YouTube videos</li>
                    <li>Checking emails</li>
                    <li>Watching 4K video</li>
                  </ul>
                </div>
              </div>
              <div className="vc_btn3-container  text-left margin-bottom">
                
          <Link to="/contact"
                  className="button button--is-big  button--generic width--standard"
                  rel="nofollow noopener"
                 
                >
                  Get  Internet
                </Link>
              </div>
            </div>
            <div className="columns small-12 medium-24 large-4">
              <div className="wpb_single_image wpb_content_element vc_align_center">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={772}
                      height={772}
                      src={first1}
                      className="vc_single_image-img attachment-medium"
                      alt="Speed dial showing 500 Mbps"
                      decoding="async"
                      title="_CTV-_-500Mbps"
                      // srcSet="https://www.cabletv.com/app/uploads/CTV-_-500Mbps.png 772w, https://www.cabletv.com/app/uploads/CTV-_-500Mbps-150x150.png 150w"
                      sizes="(max-width: 772px) 100vw, 772px"
                    />
                  </div>
                </figure>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3 style={{ textAlign: "center" }}>
                     Internet Ultra
                  </h3>
                  <h3 style={{ textAlign: "center" }}>(500 Mbps)</h3>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                    <strong>Common uses:</strong>
                  </p>
                  <ul>
                    <li>For three to five internet users</li>
                    <li>Streaming on 3 to 5 devices</li>
                    <li>Online gaming</li>
                  </ul>
                </div>
              </div>
              <div className="vc_btn3-container  text-left margin-bottom">
                <Link
                  href="https://go.cabletv.com/aff_c?offer_id=1564&aff_id=1003"
                  className="button button--is-big  button--generic width--standard"
                  rel="nofollow noopener"
                  target="_blank"
                >
                  Get Internet Ultra
                </Link>
              </div>
            </div>
            <div className="columns small-12 medium-24 large-4">
              <div className="wpb_single_image wpb_content_element vc_align_center">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={773}
                      height={772}
                      src={first2}
                      className="vc_single_image-img attachment-medium"
                      alt="1,000 Mbps"
                      decoding="async"
                      title="_CTV-_-1,000Mbps"
                      // srcSet="https://www.cabletv.com/app/uploads/CTV-_-1000Mbps.png 773w, https://www.cabletv.com/app/uploads/CTV-_-1000Mbps-150x150.png 150w"
                      sizes="(max-width: 773px) 100vw, 773px"
                    />
                  </div>
                </figure>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3 style={{ textAlign: "center" }}> Internet Gig</h3>
                  <h3 style={{ textAlign: "center" }}>(1,000 Mbps)</h3>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                    <strong>Common uses:</strong>
                  </p>
                  <ul>
                    <li>For five or more internet users</li>
                    <li>Streaming on 5 or more devices</li>
                    <li>Regular video calls for more than two people</li>
                  </ul>
                </div>
              </div>
              <div className="vc_btn3-container  text-left margin-bottom">
                <Link
                  to="/contact"
                  className="button button--is-big  button--generic width--standard"
                  rel="nofollow noopener"
                >
                  Get Internet Gig
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-12 large-12">
          <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
            <span className="vc_sep_holder vc_sep_holder_l">
              <span className="vc_sep_line" />
            </span>
            <span className="vc_sep_holder vc_sep_holder_r">
              <span className="vc_sep_line" />
            </span>
          </div>
          <div className="vc_empty_space" style={{ height: 32 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2>
                <b> antivirus software included</b>
              </h2>
            </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p>
                <span style={{ fontWeight: 400 }}>
                Everyone who uses the internet should prioritize their safety, so who wouldn't want additional security, especially if it was free?
                  Fortunately,  Internet services come with complimentary antivirus software for a maximum of 10 devices.
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                Viruses, spyware, and bots are just a few of the harmful malware that 's Security Suite guards against. Because the software updates automatically, as long as you continue to be an active  customer, you can always be sure that your devices are secure.Excerpt
                </span>
              </p>
              <p>
                <span style={{ fontWeight: 400 }}>
                An AntiBot Scanner is another feature of Security Suite for Windows users, which you may use to get rid of unwanted malware from your devices. Additionally, the program enables parents to set Parental Controls, which may be used to monitor their children's internet activity.
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-12 large-12">
          <span
            name="Deals"
            id="Deals"
            className="section_anchor "
            data-anchor="Deals"
          />
          <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
            <span className="vc_sep_holder vc_sep_holder_l">
              <span className="vc_sep_line" />
            </span>
            <span className="vc_sep_holder vc_sep_holder_r">
              <span className="vc_sep_line" />
            </span>
          </div>
          <div className="vc_empty_space" style={{ height: 32 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2>Save up to $500 with  internet deals</h2>
            </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p>
              Do you want to use 's affordable internet service?  offers a fantastic assortment of internet deals.s:
              </p>
              <ul>
                <li>
                Early termination fees up to $500 will be covered by  as part of its Contract Buyout Program.
                </li>
                <li>
                  Get a free year of{" "}
                  <Link to="">Peacock Premium</Link>includes a subscription to  Television. Internet users can sign up for a free 90-day Peacock Premium trial.
                </li>
                <li>
                  Bundle <Link to=""> Mobile</Link> with your
                   internet plan for affordable cell phone rates.
                </li>
                <li>Get a free internet modem with any internet plan.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-12 large-12">
          <section className="vc_cta3-container">
            <div className="vc_general vc_cta3 vc_cta3-style-outline vc_cta3-shape-rounded vc_cta3-align-left vc_cta3-color-blue vc_cta3-icons-on-border vc_cta3-icon-size-md vc_cta3-icons-in-box vc_cta3-icons-top">
              <div className="vc_cta3-icons">
                <div className="vc_icon_element vc_icon_element-outer vc_icon_element-align-left vc_icon_element-have-style">
                  <div className="vc_icon_element-inner vc_icon_element-color-juicy_pink vc_icon_element-have-style-inner vc_icon_element-size-md vc_icon_element-style-rounded vc_icon_element-background vc_icon_element-background-color-white">
                    <span className="vc_icon_element-icon fa fa-lightbulb-o" />
                  </div>
                </div>
              </div>
              <div className="vc_cta3_content-container">
                <div className="vc_cta3-content">
                  <header className="vc_cta3-content-header" />
                  <p>
                    <b>Pro tip: </b>Want to dig deeper into  internet?
                    Check out our{" "}
                    <Link to=""> internet review</Link>{" "}
                    for our full take on the internet provider.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-12 large-12">
          <span
            name="Features"
            id="Features"
            className="section_anchor "
            data-anchor="Features"
          />
          <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
            <span className="vc_sep_holder vc_sep_holder_l">
              <span className="vc_sep_line" />
            </span>
            <span className="vc_sep_holder vc_sep_holder_r">
              <span className="vc_sep_line" />
            </span>
          </div>
          <div className="vc_empty_space" style={{ height: 32 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2>Learn more about  internet features</h2>
            </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p>
              You will receive much more than just a home internet connection when you join up for  internet. Together, we will dissect every feature included in your  internet subscription.
              </p>
            </div>
          </div>
          <div className="row ">
            <div className="columns small-12 medium-24 large-2">
              <div className="wpb_single_image wpb_content_element vc_align_center">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={150}
                      height={150}
                      src="../app/uploads/CTV_wifi_icon.svg"
                      className="vc_single_image-img attachment-thumbnail"
                      alt="Wifi icon"
                      decoding="async"
                      title="CTV_wifi_icon"
                      srcSet="https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 150w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 1115w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 1565w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 1536w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 2048w, https://www.cabletv.com/app/uploads/CTV_wifi_icon.svg 60w"
                      sizes="(max-width: 150px) 100vw, 150px"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="columns small-12 medium-24 large-10">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3> WiFi</h3>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                  Obtain a  WiFi router along with your internet package to let  take care of the internet setup. Renting 's WiFi router comes with a $5 monthly fee.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="columns small-12 medium-24 large-2">
              <div className="wpb_single_image wpb_content_element vc_align_center">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={150}
                      height={150}
                      src="../app/uploads/CTV_location_pin_icon.svg"
                      className="vc_single_image-img attachment-thumbnail"
                      alt="Location pin icon"
                      decoding="async"
                      title="CTV_location_pin_icon"
                      srcSet="https://www.cabletv.com/app/uploads/CTV_location_pin_icon.svg 150w, https://www.cabletv.com/app/uploads/CTV_location_pin_icon.svg 1115w, https://www.cabletv.com/app/uploads/CTV_location_pin_icon.svg 1565w, https://www.cabletv.com/app/uploads/CTV_location_pin_icon.svg 1536w, https://www.cabletv.com/app/uploads/CTV_location_pin_icon.svg 2048w, https://www.cabletv.com/app/uploads/CTV_location_pin_icon.svg 60w"
                      sizes="(max-width: 150px) 100vw, 150px"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="columns small-12 medium-24 large-10">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3> hotspots</h3>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                     Internet customers get access to ’s{" "}
                    <Link to="">Out-of-Home WiFi</Link>Obtain a  WiFi router along with your internet package to let  take care of the internet setup. Renting 's WiFi router comes with a $5 monthly fee.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="columns small-12 medium-24 large-2">
              <div className="wpb_single_image wpb_content_element vc_align_center">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={150}
                      height={150}
                      src="../app/uploads/CTV_cloud_download_icon.svg"
                      className="vc_single_image-img attachment-thumbnail"
                      alt="Cloud download icon"
                      decoding="async"
                      title="CTV_cloud_download_icon"
                      srcSet="https://www.cabletv.com/app/uploads/CTV_cloud_download_icon.svg 150w, https://www.cabletv.com/app/uploads/CTV_cloud_download_icon.svg 1115w, https://www.cabletv.com/app/uploads/CTV_cloud_download_icon.svg 1565w, https://www.cabletv.com/app/uploads/CTV_cloud_download_icon.svg 1536w, https://www.cabletv.com/app/uploads/CTV_cloud_download_icon.svg 2048w, https://www.cabletv.com/app/uploads/CTV_cloud_download_icon.svg 60w"
                      sizes="(max-width: 150px) 100vw, 150px"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="columns small-12 medium-24 large-10">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3>No data caps</h3>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                  With 's unlimited data policy, you may eliminate data overage costs forever. Your family may download as many games and stream 4K videos as they'd like without worrying about data limitations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="columns small-12 medium-24 large-2">
              <div className="wpb_single_image wpb_content_element vc_align_center">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={150}
                      height={150}
                      src="../app/uploads/CTV_secure_lock_icon.svg"
                      className="vc_single_image-img attachment-thumbnail"
                      alt="Lock with checkmark icon"
                      decoding="async"
                      title="CTV_secure_lock_icon"
                      srcSet="https://www.cabletv.com/app/uploads/CTV_secure_lock_icon.svg 150w, https://www.cabletv.com/app/uploads/CTV_secure_lock_icon.svg 1115w, https://www.cabletv.com/app/uploads/CTV_secure_lock_icon.svg 1565w, https://www.cabletv.com/app/uploads/CTV_secure_lock_icon.svg 1536w, https://www.cabletv.com/app/uploads/CTV_secure_lock_icon.svg 2048w, https://www.cabletv.com/app/uploads/CTV_secure_lock_icon.svg 60w"
                      sizes="(max-width: 150px) 100vw, 150px"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="columns small-12 medium-24 large-10">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3>Parental controls</h3>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                  Customers of  Internet may ensure that their children are safely accessing the internet by using the free Security Suite from . Among the functions of Security Suite are adult website screening, site blocking, time limitations for surfing, and browsing activity records.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="columns small-12 medium-24 large-2">
              <div className="wpb_single_image wpb_content_element vc_align_center">
                <figure className="wpb_wrapper vc_figure">
                  <div className="vc_single_image-wrapper   vc_box_border_grey">
                    <img
                      width={150}
                      height={150}
                      src="../app/uploads/CTV_router_icon.svg"
                      className="vc_single_image-img attachment-thumbnail"
                      alt="Router icon"
                      decoding="async"
                      title="CTV_router_icon"
                      srcSet="https://www.cabletv.com/app/uploads/CTV_router_icon.svg 150w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 1115w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 1565w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 1536w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 2048w, https://www.cabletv.com/app/uploads/CTV_router_icon.svg 60w"
                      sizes="(max-width: 150px) 100vw, 150px"
                    />
                  </div>
                </figure>
              </div>
            </div>
            <div className="columns small-12 medium-24 large-10">
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <h3>Free modem</h3>
                </div>
              </div>
              <div className="wpb_text_column wpb_content_element ">
                <div className="wpb_wrapper">
                  <p>
                  With their internet package, all  Internet customers receive a complimentary modem. To connect your house to 's internet network, you'll need a modem.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-12 large-12">
          <span
            name="FAQ"
            id="FAQ"
            className="section_anchor "
            data-anchor="FAQ"
          />
          <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
            <span className="vc_sep_holder vc_sep_holder_l">
              <span className="vc_sep_line" />
            </span>
            <span className="vc_sep_holder vc_sep_holder_r">
              <span className="vc_sep_line" />
            </span>
          </div>
          <div className="vc_empty_space" style={{ height: 32 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <h2> internet FAQ</h2>
            </div>
          </div>
          <section className="section">
            <div className="row align-left">
              <div className="margin--bottom-15">
                <div className="toggle-option__container">
                  <Link
                    to=""
                    id="faq-opt0"
                    className="link--is-flex link--inherits-color"
                    data-toggle="faq-opt0-minus faq-opt0-plus faq-opt0-text"
                  >
                    <div className="toggle-option">
                      <div
                        id="faq-opt0-plus"
                        className="toggle-icon toggle--is-hidden"
                        data-toggler="toggle--is-shown"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-plus" />
                        </svg>
                      </div>
                      <div
                        id="faq-opt0-minus"
                        className="toggle-icon"
                        data-toggler="toggle--is-hidden"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-minus" />
                        </svg>
                      </div>
                      <div className="toggle-label">
                        <h3>What is the cheapest  internet plan?</h3>
                      </div>
                    </div>
                  </Link>
                  <div
                    id="faq-opt0-text"
                    className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                    data-toggler="toggle--is-hidden"
                  >
                    <p>
                      The cheapest  Internet plan is  Internet.
                       Internet costs $49.99 per month for the first 12
                      months and includes download speeds of up to 300 Mbps.
                    </p>
                  </div>
                </div>
                <div className="toggle-option__container">
                  <Link
                    to=""
                    id="faq-opt1"
                    className="link--is-flex link--inherits-color"
                    data-toggle="faq-opt1-minus faq-opt1-plus faq-opt1-text"
                  >
                    <div className="toggle-option">
                      <div
                        id="faq-opt1-plus"
                        className="toggle-icon toggle--is-hidden"
                        data-toggler="toggle--is-shown"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-plus" />
                        </svg>
                      </div>
                      <div
                        id="faq-opt1-minus"
                        className="toggle-icon"
                        data-toggler="toggle--is-hidden"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-minus" />
                        </svg>
                      </div>
                      <div className="toggle-label">
                        <h3>How much is  internet on its own?</h3>
                      </div>
                    </div>
                  </Link>
                  <div
                    id="faq-opt1-text"
                    className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                    data-toggler="toggle--is-hidden"
                  >
                    <p>
                       Internet prices start at $49.99 per month for the
                      first 12 months. ’s prices will increase if you
                      include add-ons like a Wi-FI router rental or a 
                      TV plan.
                    </p>
                  </div>
                </div>
                <div className="toggle-option__container">
                  <Link
                    to=""
                    id="faq-opt2"
                    className="link--is-flex link--inherits-color"
                    data-toggle="faq-opt2-minus faq-opt2-plus faq-opt2-text"
                  >
                    <div className="toggle-option">
                      <div
                        id="faq-opt2-plus"
                        className="toggle-icon toggle--is-hidden"
                        data-toggler="toggle--is-shown"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-plus" />
                        </svg>
                      </div>
                      <div
                        id="faq-opt2-minus"
                        className="toggle-icon"
                        data-toggler="toggle--is-hidden"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-minus" />
                        </svg>
                      </div>
                      <div className="toggle-label">
                        <h3>What is ’s fastest internet speed?</h3>
                      </div>
                    </div>
                  </Link>
                  <div
                    id="faq-opt2-text"
                    className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                    data-toggler="toggle--is-hidden"
                  >
                    <p>
                      ’s fastest internet speed is 1,000 Mbps. This
                      speed is available on the  Internet Gig plan,
                      which costs $89.99 per month for the first 12 months.
                    </p>
                  </div>
                </div>
                <div className="toggle-option__container">
                  <Link
                    to=""
                    id="faq-opt3"
                    className="link--is-flex link--inherits-color"
                    data-toggle="faq-opt3-minus faq-opt3-plus faq-opt3-text"
                  >
                    <div className="toggle-option">
                      <div
                        id="faq-opt3-plus"
                        className="toggle-icon toggle--is-hidden"
                        data-toggler="toggle--is-shown"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-plus" />
                        </svg>
                      </div>
                      <div
                        id="faq-opt3-minus"
                        className="toggle-icon"
                        data-toggler="toggle--is-hidden"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-minus" />
                        </svg>
                      </div>
                      <div className="toggle-label">
                        <h3>How much is  after the first year?</h3>
                      </div>
                    </div>
                  </Link>
                  <div
                    id="faq-opt3-text"
                    className="toggle-option__section toggle--has-border-bottom-grayscale-color3"
                    data-toggler="toggle--is-hidden"
                  >
                    <p>
                       internet and TV plans cost around $70 or more
                      after the first year. But in some markets,  will
                      lock in your promotional discount for two years.
                    </p>
                  </div>
                </div>
                <div className="toggle-option__container">
                  <Link
                    to=""
                    id="faq-opt4"
                    className="link--is-flex link--inherits-color"
                    data-toggle="faq-opt4-minus faq-opt4-plus faq-opt4-text"
                  >
                    <div className="toggle-option">
                      <div
                        id="faq-opt4-plus"
                        className="toggle-icon toggle--is-hidden"
                        data-toggler="toggle--is-shown"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-plus" />
                        </svg>
                      </div>
                      <div
                        id="faq-opt4-minus"
                        className="toggle-icon"
                        data-toggler="toggle--is-hidden"
                      >
                        <svg className="image--icon-size-32 color--generic-color1">
                          <use xlinkHref="#icon-minus" />
                        </svg>
                      </div>
                      <div className="toggle-label">
                        <h3>How much does it cost for  WiFi?</h3>
                      </div>
                    </div>
                  </Link>
                  <div
                    id="faq-opt4-text"
                    className="toggle-option__section"
                    data-toggler="toggle--is-hidden"
                  >
                    <p>
                      ’s Out-of-Home WiFi network is free for 
                      internet customers. ’s WiFi router costs $5 per
                      month to rent.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="row">
        <div className="columns small-12 medium-12 large-12">
          <span
            name="Disclaimer"
            id="Disclaimer"
            className="section_anchor "
            data-anchor="Disclaimer"
          />
          <div className="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
            <span className="vc_sep_holder vc_sep_holder_l">
              <span className="vc_sep_line" />
            </span>
            <span className="vc_sep_holder vc_sep_holder_r">
              <span className="vc_sep_line" />
            </span>
          </div>
          <div className="vc_empty_space" style={{ height: 32 }}>
            <span className="vc_empty_space_inner" />
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p>
                <strong>Disclaimer</strong>
              </p>
            </div>
          </div>
          <div className="wpb_text_column wpb_content_element ">
            <div className="wpb_wrapper">
              <p style={{ fontSize: 12 }}>
                <em>
                  <strong>* Internet:</strong> Limited time offer;
                  subject to change; valid to qualified residential customers
                  who have not subscribed to any services within the previous 30
                  days and who have no outstanding obligation to Charter.
                   Internet ® promotion price is $49.99/mo; standard
                  rates apply after yr. 1. Taxes, fees and surcharges extra and
                  subject to change during and after the promotional period;
                  installation/network activation, equipment and additional
                  services are extra. General Terms: INTERNET: Speed based on
                  wired connection and may vary by address. Wireless speeds may
                  vary. Services subject to all applicable service terms and
                  conditions, subject to change. Services not available in all
                  areas. Restrictions apply. Enter your address to determine
                  availability. ©2022 Charter Communications.
                </em>
              </p>
              <p style={{ fontSize: 12 }}>
                <em>
                  <strong> Internet Ultra:</strong> Limited time offer;
                  subject to change; valid to qualified residential customers
                  who have not subscribed to any services within the previous 30
                  days and who have no outstanding obligation to Charter.
                   Internet® Ultra promotion price is $69.99/mo;
                  standard rates apply after yr. 1. Taxes, fees and surcharges
                  extra and subject to change during and after the promotional
                  period; installation/network activation, equipment and
                  additional services are extra. General Terms: INTERNET: Speed
                  based on wired connection and may vary by address. Wireless
                  speeds may vary. Services subject to all applicable service
                  terms and conditions, subject to change. Services not
                  available in all areas. Restrictions apply. Enter your address
                  to determine availability. ©2022 Charter Communications.
                </em>
              </p>
              <p style={{ fontSize: 12 }}>
                <em>
                  <strong> Internet Gig:</strong> Limited time offer;
                  subject to change; valid to qualified residential customers
                  who have not subscribed to any services within the previous 30
                  days and who have no outstanding obligation to Charter.
                   Internet® Gig promotion price is $89.99/mo; standard
                  rates apply after yr. 1. Taxes, fees and surcharges extra and
                  subject to change during and after the promotional period;
                  installation/network activation, equipment and additional
                  services are extra. General Terms: INTERNET: Speed based on
                  wired connection and may vary by address. Wireless speeds may
                  vary. Services subject to all applicable service terms and
                  conditions, subject to change. Services not available in all
                  areas. Restrictions apply. Enter your address to determine
                  availability. ©2022 Charter Communications.
                </em>
              </p>
              <p style={{ fontSize: 12 }}>
                <em>
                  <strong>† Internet + TV Select:</strong> Limited time
                  offer; subject to change; valid to qualified residential
                  customers who have not subscribed to any services within the
                  previous 30 days and who have no outstanding obligation to
                  Charter.  TV® Select promotion price is $49.99/mo;
                  standard rates apply after yr. 1.  Internet® promotion
                  price is $49.99/mo; standard rates apply after yr. 1. Taxes,
                  fees and surcharges (broadcast surcharge up to $21.00/mo)
                  extra and subject to change during and after the promotional
                  period; installation/network activation, equipment and
                  additional services are extra. General Terms: INTERNET: Speed
                  based on wired connection and may vary by address. Wireless
                  speeds may vary. TV: TV equipment required; charges may apply.
                  Channel and HD programming availability based on level of
                  service. Account credentials may be required to stream some TV
                  content online. Services subject to all applicable service
                  terms and conditions, subject to change. Services not
                  available in all areas. Restrictions apply. Enter your address
                  to determine availability. ©2022 Charter Communications.
                </em>
              </p>
              <p style={{ fontSize: 12 }}>
                <em>
                  <strong> Internet + Voice:</strong> Limited time
                  offer; subject to change; valid to qualified residential
                  customers who have not subscribed to any services within the
                  previous 30 days and who have no outstanding obligation to
                  Charter.  Internet® promotion price is $49.99/mo;
                  standard rates apply after yr. 1.  Voice®: Price is
                  $14.99/mo when bundled. Taxes, fees and surcharges extra and
                  subject to change during and after the promotional period;
                  installation/network activation, equipment and additional
                  services are extra. General Terms: INTERNET: Speed based on
                  wired connection and may vary by address. Wireless speeds may
                  vary. HOME PHONE: Unlimited calling includes calls within the
                  U.S., Canada, Mexico, Puerto Rico, Guam, the Virgin Islands
                  and more. Services subject to all applicable service terms and
                  conditions, subject to change. Services not available in all
                  areas. Restrictions apply. Enter your address to determine
                  availability. ©2022 Charter Communications.
                </em>
              </p>
              <p style={{ fontSize: 12 }}>
                <em>
                  <strong>‡ Internet + TV Select + Voice:</strong>{" "}
                  Limited time offer; subject to change; valid to qualified
                  residential customers who have not subscribed to any services
                  within the previous 30 days and who have no outstanding
                  obligation to Charter.  TV® Select promotion price is
                  $49.99/mo; standard rates apply after yr. 1. 
                  Internet® promotion price is $49.99/mo; standard rates apply
                  after yr. 1.  Voice®: Price is $14.99/mo when bundled.
                  Taxes, fees and surcharges (broadcast surcharge up to
                  $21.00/mo) extra and subject to change during and after the
                  promotional period; installation/network activation, equipment
                  and additional services are extra. General Terms: INTERNET:
                  Speed based on wired connection and may vary by address.
                  Wireless speeds may vary. TV: TV equipment required; charges
                  may apply. Channel and HD programming availability based on
                  level of service. Account credentials may be required to
                  stream some TV content online. HOME PHONE: Unlimited calling
                  includes calls within the U.S., Canada, Mexico, Puerto Rico,
                  Guam, the Virgin Islands and more. Services subject to all
                  applicable service terms and conditions, subject to change.
                  Services not available in all areas. Restrictions apply. Enter
                  your address to determine availability. ©2022 Charter
                  Communications.
                </em>
              </p>
              <p style={{ fontSize: 12 }}>
                <em>
                  ◊To qualify for the Contract Buyout Program, a customer must
                  order and install a qualifying Triple Play or limited Double
                  Play promotion; offers not available in all areas. Offer
                  available to qualifying customers only who have no outstanding
                  obligations to Charter. Payment amount will be determined by
                  the Early Termination Fee on the final bill from the previous
                  provider, not to exceed $500. For Contract Buyout
                  qualifications, go to .com/buyout.
                </em>
              </p>
              <p style={{ fontSize: 12 }}>
                <em>
                  **Speed based on wired connection. Available Internet speeds
                  may vary by address. Gig capable modem required for Gig speed.
                  For a list of Gig capable modems, visit .net/modem.
                </em>
              </p>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  </div>
</div>

  </article>
</div>

  )
}

export default ShopInternet